import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { Box, Typography } from '@mui/material';
import { AuthService } from '../../api/AuthService';
import { Spinner } from '../../common/components/Spinner';
import { catchCallback } from '../../api/request';
import { AlTextField } from '../../common/components/AlTextField';
import { PageTitle } from '../../common/components/PageTitle';

const STATE = {
    LOADING: 'loading',
    TOKEN_INVALID: 'tokenInvalid',
    TOKEN_VALID: 'tokenValid',
    PASSWORD_SETTING: 'passwordSetting',
    PASSWORD_SET: 'passwordSet',
};

const formDataReducer = (formData, action) => {
    if (typeof action === 'string') {
        return {
            ...formData,
            touched: {
                ...formData.touched,
                [action]: true,
            },
        };
    }

    const { name, value } = action;
    return {
        ...formData,
        [name]: value,
    };
};

const ResetPage = ({ notifications }) => {
    const { token } = useParams();
    const { t } = useTranslation();

    const [state, setState] = useState(STATE.LOADING);
    const busy = state === STATE.PASSWORD_SETTING;

    const [formData, setFormData] = useReducer(formDataReducer, {
        password: '',
        passwordRepeat: '',
        touched: { password: false, passwordRepeat: false },
    });
    const onChange = (e) => {
        const { name, value } = e.target;
        setFormData({ name, value });
    };
    const isValid = formData.password && formData.password === formData.passwordRepeat;
    const isFormDataError = Boolean(!isValid && Object.values(formData.touched).every(Boolean));

    const checkResetToken = () => {
        const request = AuthService.checkResetToken(token);
        request.then(
            () => setState(STATE.TOKEN_VALID),
            catchCallback(() => setState(STATE.TOKEN_INVALID)),
        );
        return () => request.cancel();
    };

    const onSetPassword = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const request = AuthService.setNewPassword(formData.password, token);
        request.then(
            () => setState(STATE.PASSWORD_SET),
            catchCallback((error) => notifications.showError(error.response?.data)),
        );
    };

    useEffect(checkResetToken, []);

    const content = (() => {
        switch (state) {
            case STATE.LOADING:
                return <Spinner />;
            case STATE.TOKEN_INVALID:
                return <Alert severity="error">{t('error.ResetTokenNotValid')}</Alert>;
            case STATE.TOKEN_VALID:
            case STATE.PASSWORD_SETTING:
                return (
                    <Box
                        component="form"
                        sx={{ width: '400px', textAlign: 'right' }}
                        noValidate
                        autoComplete="off"
                        onSubmit={onSetPassword}
                    >
                        <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
                            {t('resetForm.setNewPassword')}
                        </Typography>
                        <AlTextField
                            fullWidth
                            autoFocus
                            placeholder={t('placeholder.password')}
                            alTooltip={t('tooltip.resetForm.password')}
                            onChange={onChange}
                            name="password"
                            onBlur={() => setFormData('password')}
                            error={isFormDataError}
                            value={formData.password}
                            required={true}
                            sx={{ mb: 1.5 }}
                            disabled={busy}
                            type="password"
                            inputProps={{ autoComplete: 'new-password' }}
                        />
                        <AlTextField
                            fullWidth
                            placeholder={t('placeholder.passwordRepeat')}
                            alTooltip={t('tooltip.resetForm.passwordRepeat')}
                            onChange={onChange}
                            name="passwordRepeat"
                            onBlur={() => setFormData('passwordRepeat')}
                            error={isFormDataError}
                            value={formData.passwordRepeat}
                            required={true}
                            sx={{ mb: 2 }}
                            disabled={busy}
                            type="password"
                            inputProps={{ autoComplete: 'new-password' }}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            size="large"
                            disabled={busy || !isValid}
                        >
                            {t('button.set')}
                        </Button>
                    </Box>
                );
            case STATE.PASSWORD_SET:
                return <Alert severity="info">{t('resetForm.newPasswordSet')}</Alert>;
            default:
                return null;
        }
    })();

    return (
        <>
            <PageTitle sx={{ pt: 8 }}>{t('resetForm.title')}</PageTitle>
            <Box
                sx={(theme) => ({
                    backgroundColor: theme.palette.grey[200],
                    marginBottom: '30px',
                    padding: '32px 16px',
                    display: 'flex',
                    justifyContent: 'center',
                })}
            >
                {content}
            </Box>
        </>
    );
};

export { ResetPage };
