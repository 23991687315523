import React from 'react';
import css from './BookingDetailsForm.module.css';
import { Typography, Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { findLang } from '../../../common/models/lang';

const Terms = ({ booking, onCheckboxChange }) => {
    const { t, i18n } = useTranslation();

    const links = {
        de: {
            terms: 'https://www.airportliner.com/agb/',
            policy: 'https://www.airportliner.com/datenschutzerklaerung/',
        },
        en: {
            terms: 'https://www.airportliner.com/en/terms-of-conditions/',
            policy: 'https://www.airportliner.com/en/privacy/',
        },
    };

    return (
        <div className={css.termsBox}>
            <div className={css.termsInnerBox}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={booking.termsAndPolicyAccepted}
                            onChange={onCheckboxChange}
                            name="termsAndPolicyAccepted"
                            inputProps={{ autoComplete: 'off' }}
                        />
                    }
                    label={
                        <Typography>
                            {t('booking.acceptTerms1')}{' '}
                            <Typography
                                component="a"
                                color="primary.main"
                                href={links[findLang(i18n.language).i18nCode].terms}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                {t('booking.acceptTerms2')}
                            </Typography>{' '}
                            {t('booking.acceptTerms3')}{' '}
                            <Typography
                                component="a"
                                color="primary.main"
                                href={links[findLang(i18n.language).i18nCode].policy}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                {t('booking.acceptTerms4')}
                            </Typography>
                            {t('booking.acceptTerms5')}
                            <span>*</span>
                        </Typography>
                    }
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={booking.subscribed}
                            onChange={onCheckboxChange}
                            name="subscribed"
                            inputProps={{ autoComplete: 'off' }}
                        />
                    }
                    label={<Typography>{t('booking.acceptSubscription')}</Typography>}
                />
            </div>
        </div>
    );
};

export { Terms };
