import Axios from 'axios';
import qs from 'qs';
import { CurrentUser } from '../auth/services/CurrentUser';
import { ENV } from '../env';
import { getIn } from '../common/utils/getIn';

const instance = Axios.create({
    baseURL: ENV.APP_BACKEND_URL,
    timeout: 30000,
    paramsSerializer: (params) => qs.stringify(params),
});

instance.interceptors.request.use(function (config) {
    const user = CurrentUser.get();
    if (user && !config.url.includes('auth/login') && !config.url.includes('auth/reset')) {
        config.headers.Authorization = buildAuthorization(user.token);
    }
    return config;
});

instance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (getIn(error, ['response', 'status']) === 401) {
            const authorization = getIn(error, ['config', 'headers', 'Authorization']);
            const url = getIn(error, ['config', 'url']);
            if (authorization && !url.includes('auth/reset')) {
                const user = CurrentUser.get();
                if (user && buildAuthorization(user.token) === authorization) {
                    CurrentUser.set(null);
                }
                delete error.config.headers.Authorization;
                return Axios(error.config);
            }
        }
        return Promise.reject(error);
    },
);

const buildAuthorization = (token) => `Bearer ${token}`;

export default instance;
