import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { ControlledNavLink } from '../../common/components/ControlledNavLink';

const BookingNavigation = ({ url, allValidationErrors }) => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                display: 'flex',
                marginTop: '35px',
                padding: '0 16px',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <ControlledNavLink to={`${url}/transfer`}>{t('booking.navigation.transfer')}</ControlledNavLink>
            <Box component="span" sx={{ mx: 1 }}>
                &gt;
            </Box>
            <ControlledNavLink to={`${url}/category`} disabled={Boolean(allValidationErrors.transferForm)}>
                {t('booking.navigation.category')}
            </ControlledNavLink>
            <Box component="span" sx={{ mx: 1 }}>
                &gt;
            </Box>
            <ControlledNavLink
                to={`${url}/details`}
                disabled={Boolean(allValidationErrors.transferForm || allValidationErrors.categoryList)}
            >
                {t('booking.navigation.details')}
            </ControlledNavLink>
        </Box>
    );
};

export { BookingNavigation };
