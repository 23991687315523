import { cancellableRequest } from './request';

const AuthService = {
    login: (data) => cancellableRequest({ method: 'post', url: '/auth/login', data }),
    reset: (data) => cancellableRequest({ method: 'post', url: '/auth/reset', data }),
    checkResetToken: (token) =>
        cancellableRequest({ method: 'get', url: '/auth/reset', headers: { Authorization: `Bearer ${token}` } }),
    setNewPassword: (password, token) =>
        cancellableRequest({
            method: 'put',
            url: '/auth/reset',
            data: { password },
            headers: { Authorization: `Bearer ${token}` },
        }),
};

export { AuthService };
