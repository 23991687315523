import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { AlTextField } from '../../../common/components/AlTextField';
import { useTranslation } from 'react-i18next';
import { checkRequired } from '../../../common/utils/validation';
import { AuthService } from '../../../api/AuthService';

const LoginForm = ({ setUser, onCancel, notifications, onReset }) => {
    const { t } = useTranslation();
    const [busy, setBusy] = useState(false);
    const [login, setLogin] = useState('');
    const [loginTouched, setLoginTouched] = useState(false);
    const isLoginError = checkRequired(login) && loginTouched;
    const [password, setPassword] = useState('');
    const [passwordTouched, setPasswordTouched] = useState(false);
    const isPasswordError = checkRequired(password) && passwordTouched;
    const isValid = !checkRequired(login) && !checkRequired(password);

    const onLogin = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setBusy(true);
        notifications.clearError();
        AuthService.login({ login, password })
            .then((responseUser) => {
                setUser(responseUser);
                onCancel();
            })
            .catch((error) => {
                notifications.showError(error.response?.data);
                setBusy(false);
            });
    };

    return (
        <form noValidate autoComplete="off" onSubmit={onLogin} data-cy="loginForm">
            <AlTextField
                fullWidth
                autoFocus
                placeholder={t('placeholder.login')}
                onChange={(e) => setLogin(e.target.value)}
                onBlur={() => setLoginTouched(true)}
                error={isLoginError}
                value={login}
                required={true}
                sx={{ mb: 1.5 }}
                disabled={busy}
                alTooltip={t('tooltip.resetForm.login')}
                name={'login'}
            />
            <AlTextField
                fullWidth
                placeholder={t('placeholder.password')}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={() => setPasswordTouched(true)}
                error={isPasswordError}
                value={password}
                required={true}
                sx={{ mb: 2 }}
                disabled={busy}
                type="password"
                name={'password'}
            />
            <Box
                sx={{
                    textAlign: 'right',
                    marginBottom: 1.5,
                }}
            >
                <Button type="button" size="small" onClick={onReset}>
                    {t('button.resetPassword')}
                </Button>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button type="submit" variant="contained" color="secondary" size="large" disabled={busy || !isValid}>
                    {t('button.login')}
                </Button>
                <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={onCancel}
                    disabled={busy}
                >
                    {t('button.cancel')}
                </Button>
            </Box>
        </form>
    );
};

export { LoginForm };
