import { TRANSFER } from '../../models/transfer';
import { checkRequired, checkPositiveNumber, checkPositiveOrZeroNumber } from '../../../common/utils/validation';
import { getIn } from '../../../common/utils/getIn';
import { setIn } from '../../../common/utils/setIn';
import { hasValidationError } from '../../../common/utils/hasValidationError';
import moment from 'moment';
import { AIRPORTS } from './airports';

const transferFormValidationErrors = ({ firstTransfer, secondTransfer }) => {
    const validationErrors = (() =>
        [firstTransfer, secondTransfer].filter(Boolean).reduce((errors, transfer, index) => {
            const setError = (fields, checks) => {
                fields.forEach((field) => {
                    setIn(
                        errors,
                        `transfers.${index}.${field}`,
                        checks.map((check) => check(getIn(transfer, field))).find(Boolean) || false,
                    );
                });
            };
            // always required
            let requiredFields = ['addressFrom.placeValue', 'addressTo.placeValue'];
            // transfer type depend required
            switch (transfer.typeId) {
                case TRANSFER.FROM_MUNICH:
                case TRANSFER.FROM_NUREMBERG:
                    requiredFields = [
                        ...requiredFields,
                        'flight.date',
                        'flight.time',
                        'flight.flightNumber',
                        'flight.flightDestination',
                    ];
                    break;
                case TRANSFER.TO_MUNICH:
                case TRANSFER.TO_NUREMBERG:
                    requiredFields = [
                        ...requiredFields,
                        'arrivalTime',
                        'flight.date',
                        'flight.time',
                        'flight.flightNumber',
                        'flight.flightDestination',
                    ];
                    break;
                case TRANSFER.SPECIAL:
                default:
                    requiredFields = [...requiredFields, 'tripDate', 'tripTime'];
                    break;
            }
            // if not airport is entered then street number is required
            ['addressFrom', 'addressTo']
                .filter((addressName) =>
                    AIRPORTS.every((airportCode) => {
                        const hasAddress = !!getIn(transfer, `${addressName}.id`);
                        const isNotAirport = airportCode !== getIn(transfer, `${addressName}.id`);
                        const hasStreet = !!getIn(transfer, `${addressName}.street`);
                        const hasNotStreetNumber = !getIn(transfer, `${addressName}.streetNumber`);

                        return hasAddress && isNotAirport && hasStreet && hasNotStreetNumber;
                    }),
                )
                .forEach((addressName) => {
                    requiredFields = [...requiredFields, `${addressName}.number`];
                });

            // if not airport is entered then gPlaceId has to be defined
            ['addressFrom', 'addressTo']
                .filter((addressName) =>
                    AIRPORTS.every((airportCode) => {
                        const isNotAirport = airportCode !== getIn(transfer, `${addressName}.id`);
                        const hasNotGPlaceId = !getIn(transfer, `${addressName}.gPlaceId`);

                        return isNotAirport && hasNotGPlaceId;
                    }),
                )
                .forEach((addressName) => {
                    requiredFields = [...requiredFields, `${addressName}.gPlaceId`];
                });

            setError(requiredFields, [checkRequired]);
            setError(['adults'], [checkRequired, checkPositiveNumber]);
            setError(['children'], [checkPositiveOrZeroNumber]);

            // arrivalTime < flight.time
            if (
                [TRANSFER.TO_MUNICH, TRANSFER.TO_NUREMBERG].includes(transfer.typeId) &&
                !getIn(errors, `transfers.${index}.flight.time`) &&
                !getIn(errors, `transfers.${index}.arrivalTime`) &&
                timeStrToNumber(transfer.flight.time) < timeStrToNumber(transfer.arrivalTime)
            ) {
                setError(['flight.time', 'arrivalTime'], [() => 'incorrect']);
            }
            return errors;
        }, {}))();

    if (secondTransfer) {
        // second transfer date should be after first transfer date
        switch (firstTransfer.typeId) {
            case TRANSFER.FROM_MUNICH:
            case TRANSFER.FROM_NUREMBERG:
            case TRANSFER.TO_MUNICH:
            case TRANSFER.TO_NUREMBERG:
                const time1 = Number(moment(firstTransfer.flight.date, 'YYYY-MM-DD'));
                const time2 = Number(moment(secondTransfer.flight.date, 'YYYY-MM-DD'));
                if (
                    time1 > time2 ||
                    (time1 === time2 &&
                        timeStrToNumber(firstTransfer.flight.time) > timeStrToNumber(secondTransfer.flight.time))
                ) {
                    [
                        'transfers.0.flight.date',
                        'transfers.0.flight.time',
                        'transfers.1.flight.date',
                        'transfers.1.flight.time',
                    ].forEach((key) => setIn(validationErrors, key, 'incorrect'));
                }
                break;

            case TRANSFER.SPECIAL:
            default:
                // not supported yet
                break;
        }

        // arrivalTime < flight.time
        if (
            [TRANSFER.TO_MUNICH, TRANSFER.TO_NUREMBERG].includes(secondTransfer.typeId) &&
            firstTransfer.flight.date === secondTransfer.flight.date &&
            !getIn(validationErrors, `transfers.0.flight.time`) &&
            !getIn(validationErrors, `transfers.1.arrivalTime`) &&
            timeStrToNumber(secondTransfer.arrivalTime) < timeStrToNumber(firstTransfer.flight.time)
        ) {
            setIn(validationErrors, 'transfers.0.flight.time', 'incorrect');
            setIn(validationErrors, 'transfers.0.flight.date', 'incorrect');
            setIn(validationErrors, 'transfers.1.arrivalTime', 'incorrect');
        }
    }

    const isValid = !hasValidationError(validationErrors);

    return isValid ? null : validationErrors;
};

const timeStrToNumber = (timeStr) => {
    if (!timeStr) {
        return 0;
    }
    const items = timeStr.split(':');
    return parseInt(items[0], 10) * 60 + parseInt(items[1], 10);
};

export { transferFormValidationErrors };
