import React from 'react';
import { useTranslation } from 'react-i18next';
import { AlTextField } from '../../../common/components/AlTextField';
import { InputRow } from './InputRow';
import { faUserFriends, faChild } from '@fortawesome/free-solid-svg-icons';
import { DetailToAirportFields } from './DetailToAirportFields';
import { DetailA2BFields } from './DetailA2BFields';
import { TRANSFER } from '../../models/transfer';
import { DetailFromAirportFields } from './DetailFromAirportFields';

const DetailFields = ({ transfer, updateTransfer, touchTransfer, transferError }) => {
    const { t } = useTranslation();

    const onChange = (e) => {
        updateTransfer(e.target);
    };

    const onNumberChange = (e) => {
        const { value, name } = e.target;
        updateTransfer({ value: parseInt(value) || '', name });
    };

    const onDateChange = ({ date, name }) => {
        const value = (date && date.isValid() && date.format('YYYY-MM-DD')) || null;
        updateTransfer({ value, name });
    };

    const onTimeChange = ({ time, name }) => {
        updateTransfer({ value: `${time}:00`, name });
    };

    const content = (() => {
        if ([TRANSFER.FROM_MUNICH, TRANSFER.FROM_NUREMBERG].includes(transfer.typeId)) {
            return (
                <DetailFromAirportFields
                    transfer={transfer}
                    updateTransfer={updateTransfer}
                    touchTransfer={touchTransfer}
                    transferError={transferError}
                    onTimeChange={onTimeChange}
                    onDateChange={onDateChange}
                    onChange={onChange}
                />
            );
        }
        if ([TRANSFER.TO_MUNICH, TRANSFER.TO_NUREMBERG].includes(transfer.typeId)) {
            return (
                <DetailToAirportFields
                    transfer={transfer}
                    updateTransfer={updateTransfer}
                    touchTransfer={touchTransfer}
                    transferError={transferError}
                    onTimeChange={onTimeChange}
                    onDateChange={onDateChange}
                    onChange={onChange}
                />
            );
        }
        return (
            <DetailA2BFields
                transfer={transfer}
                onTimeChange={onTimeChange}
                onDateChange={onDateChange}
                touchTransfer={touchTransfer}
                transferError={transferError}
            />
        );
    })();

    return (
        <>
            {content}
            <InputRow icon={faUserFriends}>
                <AlTextField
                    fullWidth
                    name="adults"
                    type="number"
                    inputProps={{ min: 1 }}
                    onBlur={touchTransfer}
                    onChange={onNumberChange}
                    value={transfer.adults || ''}
                    placeholder={t('adults')}
                    alTooltip={t('tooltip.adults')}
                    required={true}
                    error={transferError('adults')}
                />
            </InputRow>
            <InputRow icon={faChild}>
                <AlTextField
                    fullWidth
                    name="children"
                    type="number"
                    inputProps={{ min: 0 }}
                    onChange={onNumberChange}
                    value={transfer.children || ''}
                    placeholder={t('children')}
                    alTooltip={t('tooltip.children')}
                    onBlur={touchTransfer}
                    error={transferError('children')}
                />
            </InputRow>
        </>
    );
};

export { DetailFields };
