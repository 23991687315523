import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

const CompanyAddress = ({ data }) => {
    const { t } = useTranslation();
    const { street, zip, place, customerNumber } = data;

    return (
        <Box
            component="section"
            sx={{
                boxSizing: 'border-box',
                paddingLeft: '10px',
                width: '100%',
                maxWidth: '500px',
                marginBottom: '10px',
            }}
        >
            {customerNumber && (
                <Typography sx={{ margin: '0 0 5px' }}>{`${t('customerNumber')}: ${customerNumber}`}</Typography>
            )}
            <Typography sx={{ margin: '0 0 5px' }}>{`${t('street')}: ${street}`}</Typography>
            <Typography sx={{ margin: '0 0 5px' }}>{`${t('zip')}: ${zip}`}</Typography>
            <Typography sx={{ margin: '0 0 5px' }}>{`${t('city')}: ${place}`}</Typography>
        </Box>
    );
};

export { CompanyAddress };
