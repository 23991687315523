const SERVICE_TYPE_ID = {
    COLLECTIVE: 1,
    INDIVIDUAL: 2,
    EXCLUSIVE: 3,
    UPON_REQUEST: 5,
};

const getServiceTypeById = (serviceTypeId) => {
    return Object.keys(SERVICE_TYPE_ID).find((key) => SERVICE_TYPE_ID[key] === serviceTypeId);
};

const FREE_GATE_SERVICE_TYPES = [SERVICE_TYPE_ID.INDIVIDUAL, SERVICE_TYPE_ID.EXCLUSIVE];

export { SERVICE_TYPE_ID, FREE_GATE_SERVICE_TYPES, getServiceTypeById };
