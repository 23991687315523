import React from 'react';
import { NavLink } from 'react-router-dom';
import { Typography, useTheme } from '@mui/material';

const ControlledNavLink = ({ disabled, to, children }) => {
    const { palette } = useTheme();

    if (disabled) {
        return <Typography variant="body1">{children}</Typography>;
    }

    return (
        <NavLink to={to} style={{ color: palette.primary.main }} activeStyle={{ fontWeight: 600 }}>
            <Typography variant="body1" sx={{ fontWeight: 'inherit' }}>
                {children}
            </Typography>
        </NavLink>
    );
};

export { ControlledNavLink };
