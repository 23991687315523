import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { PageTitle } from '../../../common/components/PageTitle';
import css from './BookingTransferForm.module.css';
import { ReactComponent as LeftLogo } from '../../../assets/images/left_logo.svg';
import { AddressFields } from './AddressFields';
import { AIRPORT_MUC, AIRPORT_NUE } from './airports';
import { DetailFields } from './DetailFields';
import { TRANSFER } from '../../models/transfer';
import { OrderActions } from './OrderActions';

const BookingTransferForm = ({
    url,
    data: { firstTransfer, secondTransfer },
    touch: { touchFirstTransfer, touchSecondTransfer },
    update: { updateFirstTransfer, updateSecondTransfer, addTransfer, removeTransfer },
    dictionary,
    errorsSrc: { firstTransferErrorSrc, secondTransferErrorSrc },
    validationErrors,
    user,
}) => {
    const { t } = useTranslation();
    const history = useHistory();

    const isDetailPartAvailable = Boolean(firstTransfer.typeId);
    const isAddReturnTransferAvailable = Boolean(
        !secondTransfer &&
            [TRANSFER.TO_NUREMBERG, TRANSFER.FROM_NUREMBERG, TRANSFER.TO_MUNICH, TRANSFER.FROM_MUNICH].includes(
                firstTransfer.typeId,
            ),
    );
    const isRemoveReturnTransferAvailable = Boolean(secondTransfer);
    const disableSecondAddressFrom = Boolean(
        secondTransfer && [TRANSFER.FROM_MUNICH, TRANSFER.FROM_NUREMBERG].includes(secondTransfer.typeId),
    );
    const disableSecondAddressTo = Boolean(
        secondTransfer && [TRANSFER.TO_MUNICH, TRANSFER.TO_NUREMBERG].includes(secondTransfer.typeId),
    );

    const updateTransferTypes = () => {
        const addressFrom = firstTransfer.addressFrom;
        const addressTo = firstTransfer.addressTo;
        if (!addressFrom.place || !addressTo.place) {
            return;
        }

        if (addressFrom.id === AIRPORT_MUC) {
            updateFirstTransfer({ name: 'typeId', value: TRANSFER.FROM_MUNICH });
            return;
        }
        if (addressFrom.id === AIRPORT_NUE) {
            updateFirstTransfer({ name: 'typeId', value: TRANSFER.FROM_NUREMBERG });
            return;
        }
        if (addressTo.id === AIRPORT_MUC) {
            updateFirstTransfer({ name: 'typeId', value: TRANSFER.TO_MUNICH });
            return;
        }
        if (addressTo.id === AIRPORT_NUE) {
            updateFirstTransfer({ name: 'typeId', value: TRANSFER.TO_NUREMBERG });
            return;
        }
        updateFirstTransfer({ name: 'typeId', value: TRANSFER.SPECIAL });
    };

    useEffect(updateTransferTypes, [firstTransfer.addressFrom, firstTransfer.addressTo]);

    const onNext = (e) => {
        e.stopPropagation();
        e.preventDefault();

        history.push(`${url}/category`);
    };

    const isValid = !validationErrors;

    const [firstTransferError, secondTransferError] = [firstTransferErrorSrc, secondTransferErrorSrc].map(
        (transferError) => transferError.bind(null, validationErrors),
    );

    const nextButton = (
        <Button
            sx={{
                width: {
                    xs: '100%',
                    sm: 'auto',
                },
            }}
            type="submit"
            variant="contained"
            color="secondary"
            size="large"
            disabled={!isValid}
        >
            {t('button.next')}
        </Button>
    );

    return (
        <>
            <PageTitle>{t('main-form')}</PageTitle>
            <Box
                component="form"
                className={cn(css.box, secondTransfer ? css.twoTransfers : '')}
                sx={(theme) => theme.mixins.box(theme)}
                noValidate
                autoComplete="off"
                onSubmit={onNext}
                data-cy="booking-1"
            >
                <div className={css.logoBox}>
                    <LeftLogo className={css.logo} />
                    <Typography variant="h5" sx={{ mt: 2 }}>
                        {t('logo')}
                    </Typography>
                </div>
                <Box className={css.formBox}>
                    {secondTransfer && (
                        <Typography variant="h5" sx={{ mb: 2, pl: 5 }}>
                            {t('booking.transfer1')}
                        </Typography>
                    )}
                    <AddressFields
                        transfer={firstTransfer}
                        updateTransfer={updateFirstTransfer}
                        name="addressFrom"
                        touchTransfer={touchFirstTransfer}
                        transferError={firstTransferError}
                        dictionary={dictionary}
                        user={user}
                    />
                    <AddressFields
                        transfer={firstTransfer}
                        updateTransfer={updateFirstTransfer}
                        name="addressTo"
                        touchTransfer={touchFirstTransfer}
                        transferError={firstTransferError}
                        dictionary={dictionary}
                        user={user}
                    />
                    {isDetailPartAvailable && (
                        <DetailFields
                            transfer={firstTransfer}
                            updateTransfer={updateFirstTransfer}
                            touchTransfer={touchFirstTransfer}
                            transferError={firstTransferError}
                        />
                    )}
                    {!isRemoveReturnTransferAvailable && (
                        <OrderActions
                            isBookingsValid={isValid}
                            firstTransfer={firstTransfer}
                            secondTransfer={secondTransfer}
                        >
                            <Box sx={{ mt: { xs: 3, sm: 2 }, textAlign: 'right' }}>
                                {nextButton}
                                {isAddReturnTransferAvailable && (
                                    <Button
                                        sx={{
                                            width: {
                                                xs: '100%',
                                                sm: 'auto',
                                            },
                                            mt: {
                                                xs: 1.5,
                                                sm: 0,
                                            },
                                            ml: {
                                                sm: 1.5,
                                            },
                                        }}
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        size="large"
                                        onClick={addTransfer}
                                    >
                                        {t('button.addBackTransfer')}
                                    </Button>
                                )}
                            </Box>
                        </OrderActions>
                    )}
                </Box>
                {secondTransfer && (
                    <Box sx={{ ml: 3 }} className={css.formBox}>
                        {secondTransfer && (
                            <Typography variant="h5" sx={{ mb: 2, pl: 5 }}>
                                {t('booking.transfer2')}
                            </Typography>
                        )}
                        <AddressFields
                            transfer={secondTransfer}
                            updateTransfer={updateSecondTransfer}
                            name="addressFrom"
                            touchTransfer={touchSecondTransfer}
                            transferError={secondTransferError}
                            disabled={disableSecondAddressFrom}
                            dictionary={dictionary}
                            user={user}
                        />
                        <AddressFields
                            transfer={secondTransfer}
                            updateTransfer={updateSecondTransfer}
                            name="addressTo"
                            touchTransfer={touchSecondTransfer}
                            transferError={secondTransferError}
                            disabled={disableSecondAddressTo}
                            dictionary={dictionary}
                            user={user}
                        />
                        {isDetailPartAvailable && (
                            <DetailFields
                                transfer={secondTransfer}
                                updateTransfer={updateSecondTransfer}
                                touchTransfer={touchSecondTransfer}
                                transferError={secondTransferError}
                            />
                        )}
                        {isRemoveReturnTransferAvailable && (
                            <OrderActions
                                isBookingsValid={isValid}
                                firstTransfer={firstTransfer}
                                secondTransfer={secondTransfer}
                            >
                                <Box sx={{ mt: { xs: 3, sm: 2 }, textAlign: 'right' }}>
                                    {nextButton}
                                    <Button
                                        sx={{
                                            width: {
                                                xs: '100%',
                                                sm: 'auto',
                                            },
                                            ml: {
                                                sm: 1.5,
                                            },
                                            mt: {
                                                xs: 1.5,
                                                sm: 0,
                                            },
                                        }}
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        size="large"
                                        onClick={removeTransfer}
                                    >
                                        {t('button.removeBackTransfer')}
                                    </Button>
                                </Box>
                            </OrderActions>
                        )}
                    </Box>
                )}
            </Box>
        </>
    );
};

export { BookingTransferForm };
