import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { findLang } from '../../../common/models/lang';
import { CarImage } from '../CarImage';
import { currency } from '../../../common/utils/currency';
import { useHistory } from 'react-router-dom';
import { FREE_GATE_SERVICE_TYPES } from '../../models/service-type';

const BookingCategoryItem = ({ item, updateFirstTransfer, url }) => {
    const { i18n, t } = useTranslation();
    const history = useHistory();

    const langCode = findLang(i18n.language).code;

    const title = item[`title${langCode}`];
    const description = buildDescription(item[`description${langCode}`]);
    const price = currency(item.price);

    const onSelect = () => {
        updateFirstTransfer({ name: 'serviceTypeId', value: item.serviceTypeId });
        updateFirstTransfer({ name: 'serviceType', value: item });

        //known bug: if a customer unselects and navigates to page two, this will come back
        if (FREE_GATE_SERVICE_TYPES.includes(item.serviceTypeId)) {
            updateFirstTransfer({ name: 'extras.GATE', value: 1 });
        }

        history.push(`${url}/details`);
    };

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                pt: 2,
                px: 2.5,
                pb: 2.5,
                mb: 2.5,
            }}
        >
            <Stack
                sx={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    flexGrow: 1,
                }}
            >
                <CarImage url={item.descriptionImage} />
                {(price && (
                    <Box>
                        <Typography variant="h5" sx={{ mb: 0.5, textAlign: 'right' }}>
                            {t('transfer-total')}
                        </Typography>
                        <Typography variant="h4" sx={{ fontWeight: 500 }}>
                            {price}
                        </Typography>
                    </Box>
                )) || (
                    <Typography variant="body2" sx={{ textAlign: 'right' }}>
                        {t('booking.priceOnRequest')}
                    </Typography>
                )}
            </Stack>
            <Stack
                sx={{
                    flexDirection: { sm: 'row' },
                    justifyContent: { sm: 'space-between' },
                    alignItems: { sm: 'flex-end' },
                }}
            >
                <Box sx={{ mr: { sm: 2 } }}>
                    <Typography variant="h5" sx={{ mb: 1 }}>
                        {title}
                    </Typography>
                    {description}
                </Box>
                <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    onClick={onSelect}
                    data-cy="transferCategory"
                    sx={{ mt: 2, flexShrink: 0 }}
                >
                    {t('select')}
                </Button>
            </Stack>
        </Paper>
    );
};

function buildDescription(data) {
    const list = data.list && data.list.length ? data.list : undefined;
    const p = data.p;

    if (!data || (!list && !p)) {
        return null;
    }

    return (
        <>
            {p && <Typography>{p}</Typography>}
            {list && (
                <Box component="ul" sx={{ m: 0, pl: 3 }}>
                    {list.map((descriptionItem, key) => (
                        <Typography key={key} component="li">
                            {descriptionItem.item}
                        </Typography>
                    ))}
                </Box>
            )}
        </>
    );
}

export { BookingCategoryItem };
