const AIRPORT_MUC = 'MUC';
const AIRPORT_NUE = 'NUE';
const AIRPORTS = [AIRPORT_MUC, AIRPORT_NUE];
const AIRPORTS_ADDRESS = {
    [AIRPORT_MUC]: {
        name: 'Flughafen München',
        street: 'Nordallee 25',
        place: 'Freising',
        zip: '85356',
        streetNumber: '25',
    },
    [AIRPORT_NUE]: {
        name: 'Flughafen Nürnberg',
        street: 'Flughafenstraße 100',
        place: 'Nürnberg',
        zip: '90411',
        streetNumber: '100',
    },
};

export { AIRPORT_MUC, AIRPORT_NUE, AIRPORTS, AIRPORTS_ADDRESS };
