import React, { useState, useEffect } from 'react';
import 'moment/locale/de'; // Needed for date picker
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { deDE, enUS } from '@mui/x-date-pickers/locales';
import { BookingPage } from './booking/components/BookingPage/BookingPage';
import { Header } from './common/components/Header/Header';
import { Footer } from './common/components/Footer';
import { AuthDialog } from './auth/components/AuthDialog/AuthDialog';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { CurrentUser } from './auth/services/CurrentUser';
import { catchCallback } from './api/request';
import { ResetPage } from './auth/components/ResetPage';
import { getErrorMessageFromResponseError } from './common/utils/getErrorMessageFromResponseError';
import { ProfilePage } from './profile/components/ProfilePage/ProfilePage';
import { DictionaryService } from './api/DictionaryService';
import { ProfileService } from './api/ProfileService';
import { InfoPage } from './info/InfoPage';
import theme from './theme';
import { isLangDE } from './common/models/lang';

let customTheme = createTheme(theme);
customTheme = responsiveFontSizes(customTheme);

const App = () => {
    const [authDialogOpen, setAuthDialogOpen] = useState(false);
    const [user, setUserFn] = useState(undefined);
    const [error, setError] = useState(null);
    const [info, setInfo] = useState(null);
    const [dictionary, setDictionary] = useState(null);

    const { t, i18n } = useTranslation();

    const localeText = isLangDE(i18n.language.split('-')[0])
        ? deDE.components.MuiLocalizationProvider.defaultProps.localeText
        : enUS.components.MuiLocalizationProvider.defaultProps.localeText;

    // check if user token is still valid
    const checkUser = () => {
        const storedUser = CurrentUser.get();
        if (!storedUser) {
            setUser(null);
            return;
        }

        const request = ProfileService.getProfile();
        request
            .then(() => setUserFn(storedUser))
            .catch(
                catchCallback(() => {
                    // consider any error as auth error
                    setUser(null);
                }),
            );
        return () => request.cancel();
    };

    const alertOnClose = (setter) => (_e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setter(null);
    };

    const notifications = buildNotifications({ setError, setInfo, t });

    const setUser = (user) => {
        // update local storage before setting user
        CurrentUser.set(user);
        setUserFn(user);
    };

    const isNotLogged = !user && user !== undefined;
    const isLogged = Boolean(user);

    const loadDictionary = () => {
        const request = DictionaryService.get();

        request
            .then((data) => {
                setDictionary(data);
            })
            .catch(
                catchCallback((error) => {
                    console.error('Dictionary has not been loaded', error);
                }),
            );

        return () => {
            request.cancel();
        };
    };

    useEffect(() => {
        const desc = document.getElementsByTagName('meta')['description'];

        if (desc) {
            desc.content = t('meta.description');
        }

        document.title = t('meta.title');
    }, [t]);

    useEffect(loadDictionary, []);
    useEffect(checkUser, []);

    const defaultRedirect = <Redirect to="/booking" />;

    return (
        <ThemeProvider theme={customTheme}>
            <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterMoment} localeText={localeText}>
                <HashRouter>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: '100vh',
                        }}
                    >
                        <Header onLogin={() => setAuthDialogOpen(true)} onLogout={() => setUser(null)} user={user} />
                        <div style={{ flexGrow: '1' }}>
                            <Switch>
                                <Route path="/booking">
                                    <BookingPage
                                        user={user}
                                        setUser={setUser}
                                        notifications={notifications}
                                        dictionary={dictionary}
                                    />
                                </Route>
                                <Route path="/profile">
                                    {isLogged && (
                                        <ProfilePage
                                            user={user}
                                            notifications={notifications}
                                            dictionary={dictionary}
                                        />
                                    )}
                                    {isNotLogged && defaultRedirect}
                                </Route>
                                <Route path="/reset/:token">
                                    {isNotLogged && <ResetPage notifications={notifications} />}
                                    {isLogged && defaultRedirect}
                                </Route>
                                <Route path={['/info/:code/:number', '/info/:code']}>
                                    <InfoPage />
                                </Route>
                                {defaultRedirect}
                            </Switch>
                        </div>
                        <Footer />
                    </div>

                    <AuthDialog
                        open={authDialogOpen}
                        onClose={() => setAuthDialogOpen(false)}
                        setUser={setUser}
                        notifications={notifications}
                    />

                    <Snackbar open={Boolean(info)} autoHideDuration={20000} onClose={alertOnClose(setInfo)}>
                        <Alert onClose={alertOnClose(setInfo)} severity="info">
                            {info}
                        </Alert>
                    </Snackbar>

                    <Snackbar open={Boolean(error)} autoHideDuration={10000} onClose={alertOnClose(setError)}>
                        <Alert onClose={alertOnClose(setError)} severity="error">
                            {error}
                        </Alert>
                    </Snackbar>
                </HashRouter>
            </LocalizationProvider>
        </ThemeProvider>
    );
};

const buildNotifications = ({ setError, setInfo, t }) => ({
    showError: (e) => {
        const errorMessage = getErrorMessageFromResponseError(e, t);
        setError(
            <>
                {errorMessage.message}
                {errorMessage.details && errorMessage.details.map((detail, i) => <div key={i}>{detail}</div>)}
            </>,
        );
    },
    clearError: () => {
        setError(null);
    },
    showInfo: (message) => {
        setInfo(message);
    },
    clearInfo: () => {
        setInfo(null);
    },
});

export default App;
