import React from 'react';
import { MuiTelInput } from 'mui-tel-input';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

const PhoneFieldInput = styled(MuiTelInput)`
    position: relative;

    & .MuiTelInput-IconButton {
        margin-left: 4px;
    }

    & input {
        padding-left: 0;
    }

    &::after {
        content: '${(props) => (props.required ? '*' : '')}';
        position: absolute;
        right: 6px;
        top: 1px;
    }
`;

const PhoneField = ({ name, onChange, ...restProps }) => {
    const { i18n } = useTranslation();

    return (
        <PhoneFieldInput
            fullWidth
            name={name}
            langOfCountryName={i18n.language}
            onChange={(value) => onChange({ name, value })}
            {...restProps}
        />
    );
};

export { PhoneField };
