import React, { useEffect, useRef, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import { Box, Typography, FormControlLabel, Checkbox, Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ExpansionPanelSummary } from '../../../common/components/ExpansionPanelSummary';
import { extraPrice } from './extraPrice';
import { getServiceTypeById } from '../../models/service-type';
import css from './BookingDetailsForm.module.css';

const Luggage = ({ luggages, transfer, updateTransfer }) => {
    const { t } = useTranslation();
    const firstRef = useRef(null);
    const [isExpanded, setExpanded] = useState(false);

    useEffect(() => {
        if (!isExpanded) {
            return;
        }

        // TODO check if it works
        const btn = firstRef.current.querySelector('[role="button"]');

        if (btn) {
            btn.focus();
        }
    }, [isExpanded]);

    const onOnlyHandLuggageChange = (e, value) => {
        updateTransfer({ value: value ? { onlyHandLuggage: true } : {}, name: 'extras' });
    };

    const onLuggageClick = ({ name, value }) => {
        if (value || transfer.extras.onlyHandLuggage) {
            return;
        }
        updateTransfer({ name, value: 1 });
    };

    return (
        <Accordion onChange={(_, expanded) => setExpanded(expanded)}>
            <ExpansionPanelSummary text="booking.additionalLuggage">
                <Box sx={{ ml: { sm: 2 }, mr: 1 }} direction="row" display="flex">
                    <Typography variant="caption" sx={{ lineHeight: 1.2 }}>
                        <Box component="span" sx={{ mr: 1, whiteSpace: 'nowrap' }}>
                            {t('booking.pleaseNote')}
                        </Box>
                        <Box component="span">{t('booking.additionalLuggageNote')}</Box>
                    </Typography>
                </Box>
            </ExpansionPanelSummary>
            <div className={css.expansionBody}>
                <div className={css.grid} ref={firstRef}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={transfer.extras.onlyHandLuggage || false}
                                onChange={onOnlyHandLuggageChange}
                            />
                        }
                        label={<Typography>{t('extras.onlyHandLuggage')}</Typography>}
                    />
                    {luggages.map((luggage, luggageKey) => (
                        <div key={luggageKey} className={css.gridCell}>
                            <Select
                                sx={{ width: '64px' }}
                                value={transfer.extras[luggage.internalCode] || 0}
                                name={`extras.${luggage.internalCode}`}
                                onChange={updateTransfer}
                                onMouseDown={() =>
                                    onLuggageClick({
                                        name: `extras.${luggage.internalCode}`,
                                        value: transfer.extras[luggage.internalCode],
                                    })
                                }
                                disabled={transfer.extras.onlyHandLuggage}
                            >
                                {[...Array(10)].map((_, key) => (
                                    <MenuItem value={key} key={key}>
                                        {key}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Typography sx={{ ml: 1 }}>
                                {/*get the service type first, then get the price for this service type*/}
                                {t(`extras.${luggage.internalCode}`)}&nbsp;
                                {extraPrice(luggage[getServiceTypeById(transfer.serviceTypeId)])}
                            </Typography>
                        </div>
                    ))}
                </div>
            </div>
        </Accordion>
    );
};

export { Luggage };
