import React, { useEffect, useRef, useState } from 'react';
import css from './BookingDetailsForm.module.css';
import { useTranslation } from 'react-i18next';
import Accordion from '@mui/material/Accordion';
import { ExpansionPanelSummary } from '../../../common/components/ExpansionPanelSummary';
import { AlTextField } from '../../../common/components/AlTextField';

const Comment = ({ booking, onChange }) => {
    const { t } = useTranslation();
    const firstRef = useRef(null);
    const [isExpanded, setExpanded] = useState(false);

    useEffect(() => {
        if (!isExpanded) {
            return;
        }
        firstRef.current.focus();
    }, [isExpanded]);

    return (
        <Accordion onChange={(_, expanded) => setExpanded(expanded)}>
            <ExpansionPanelSummary text="add-comment" />
            <div className={css.expansionBody}>
                <div className={css.itemFullWidth}>
                    <AlTextField
                        autoComplete="off"
                        fullWidth
                        name="transferNote"
                        onChange={onChange}
                        value={booking.transferNote || ''}
                        placeholder={t('placeholder.transferNote')}
                        alTooltip={t('tooltip.transferNote')}
                        multiline
                        minRows="5"
                        InputProps={{ inputRef: firstRef }}
                    />
                </div>
            </div>
        </Accordion>
    );
};

export { Comment };
