import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import { Box, Paper, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { CarImage } from './CarImage';
import { findLang } from '../../common/models/lang';
import { TRANSFER } from '../models/transfer';
import { formattedDate } from '../../common/utils/date';
import { AIRPORTS } from './BookingTransferForm/airports';

const BookingSummary = ({ firstTransfer, secondTransfer, url }) => {
    const { i18n, t } = useTranslation();
    const langCode = findLang(i18n.language).code;

    // formats the address string differently - returns an array with a street and a zip+place part
    const getAddressFrom = (transfer) => {
        //could do this nicer without using the strings directly
        if (AIRPORTS.includes(transfer.addressFrom.id)) {
            return [t(`airport.${transfer.addressFrom.id}`)];
        }
        return [transfer.addressFrom.street, transfer.addressFrom.zip + ' ' + transfer.addressFrom.place];
    };

    const getAddressTo = (transfer) => {
        if (AIRPORTS.includes(transfer.addressTo.id)) {
            return [t(`airport.${transfer.addressTo.id}`)];
        }
        return [transfer.addressTo.street, transfer.addressTo.zip + ' ' + transfer.addressTo.place];
    };

    const isAPTransfer = (transfer) => {
        return [TRANSFER.FROM_MUNICH, TRANSFER.TO_MUNICH, TRANSFER.FROM_NUREMBERG, TRANSFER.TO_NUREMBERG].includes(
            transfer.typeId,
        );
    };

    //TODO probably these time and date helpers can be summed up and done at once - differenciation between
    // which transfer is always the same
    const timeLabel = (transfer) => {
        if ([TRANSFER.FROM_MUNICH, TRANSFER.FROM_NUREMBERG].includes(transfer.typeId)) {
            return t('summary.flight-arrival-time');
        }
        if ([TRANSFER.TO_MUNICH, TRANSFER.TO_NUREMBERG].includes(transfer.typeId)) {
            return t('summary.flight-departure-time');
        }
        return t('summary.a2b-time');
    };

    const dateLabel = (transfer) => {
        if ([TRANSFER.FROM_MUNICH, TRANSFER.FROM_NUREMBERG].includes(transfer.typeId)) {
            return t('summary.flight-arrival-date');
        }
        if ([TRANSFER.TO_MUNICH, TRANSFER.TO_NUREMBERG].includes(transfer.typeId)) {
            return t('summary.flight-departure-date');
        }
        return t('summary.a2b-date');
    };

    const date = (transfer) => {
        let date;

        if (isAPTransfer(transfer)) {
            date = transfer.flight.date;
        }

        if ([TRANSFER.SPECIAL].includes(transfer.typeId)) {
            date = transfer.tripDate;
        }

        return formattedDate(date, langCode);
    };

    const time = (transfer) => {
        let time;

        if (isAPTransfer(transfer)) {
            time = transfer.flight.time;
        }

        if ([TRANSFER.SPECIAL].includes(transfer.typeId)) {
            time = transfer.tripTime;
        }

        return String(time).split(':').slice(0, 2).join(':');
    };

    return (
        <Paper sx={{ px: 2.5, pt: 2, pb: 0.5 }}>
            <Typography variant="h5" sx={{ textAlign: 'center' }}>
                {t('summary.title')}
            </Typography>
            {[firstTransfer, secondTransfer].filter(Boolean).map((el, i) => (
                <Grid key={i} container spacing={0}>
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ mt: 1, mb: 0.5 }}>
                            {t('booking.transfer')} {secondTransfer ? i + 1 : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">{t('summary.from')}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        {getAddressFrom(el)
                            .filter(Boolean)
                            .map((addressPiece, index) => (
                                <Grid key={index}>
                                    <Typography variant="body2">{addressPiece}</Typography>
                                </Grid>
                            ))}
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">{t('summary.to')}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        {getAddressTo(el)
                            .filter(Boolean)
                            .map((addressPiece, index) => (
                                <Grid key={index}>
                                    <Typography variant="body2">{addressPiece}</Typography>
                                </Grid>
                            ))}
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">{dateLabel(el)}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">{date(el)}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">{timeLabel(el)}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">{time(el)}</Typography>
                    </Grid>
                    {/*only show flight number for ap transfers*/}
                    {isAPTransfer(el) && (
                        <>
                            <Grid item xs={6}>
                                <Typography variant="body2">{t('flight-number')}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2">{el.flight.flightNumber}</Typography>
                            </Grid>
                        </>
                    )}

                    <Grid item xs={6}>
                        <Typography variant="body2">{t('summary.passengers')}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">{el.adults + el.children}</Typography>
                    </Grid>
                </Grid>
            ))}
            <Box sx={{ mt: 1, textAlign: 'center' }}>
                <Button href={`${url}/transfer`} size="small">
                    {t('edit')}
                </Button>
            </Box>
            {firstTransfer.serviceType && (
                <>
                    <Typography variant="h6" sx={{ mt: 1.5, mb: 0.5 }}>
                        {t('booking.serviceType') + ': ' + firstTransfer.serviceType[`title${langCode}`]}
                    </Typography>
                    <Link to={`${url}/category`}>
                        <CarImage url={firstTransfer.serviceType.descriptionImage} />
                    </Link>
                </>
            )}
        </Paper>
    );
};

export { BookingSummary };
