import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import cn from 'classnames';
import css from './AlFormField.module.css';

const AlFormField = (props) => {
    const { field, alTooltip, alTooltipOut, className, required, children, alRef, ...fieldProps } = props;

    return (
        <div ref={alRef} className={cn(className, css.host, (alTooltip && !alTooltipOut && css.withTooltip) || '')}>
            {React.createElement(field, fieldProps, children)}
            {alTooltip && (
                <Tooltip title={alTooltip} placement="top" enterTouchDelay={0} leaveTouchDelay={3000}>
                    <div className={cn(css.tooltip, alTooltipOut && css.tooltipOut)}>
                        <HelpOutlineIcon sx={{ fontSize: '16px', color: 'text.secondary' }} />
                    </div>
                </Tooltip>
            )}
            {required && <div className={css.asterisk}>*</div>}
        </div>
    );
};

export { AlFormField };
