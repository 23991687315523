import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { AlTextField } from '../../../common/components/AlTextField';
import { useTranslation } from 'react-i18next';
import { checkRequired } from '../../../common/utils/validation';
import { AuthService } from '../../../api/AuthService';
import { findLang } from '../../../common/models/lang';
import { baseResetUrl } from '../../utils/baseResetUrl';

const ResetForm = ({ onCancel, notifications }) => {
    const { t, i18n } = useTranslation();

    const [busy, setBusy] = useState(false);

    const [login, setLogin] = useState('');
    const [loginTouched, setLoginTouched] = useState(false);
    const isLoginError = checkRequired(login) && loginTouched;
    const isValid = !checkRequired(login);

    const baseUrl = baseResetUrl();

    const onReset = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setBusy(true);
        notifications.clearError();
        AuthService.reset({ login, baseUrl, language: findLang(i18n.language).i18nCode })
            .then(() => {
                notifications.showInfo(t(`resetForm.emailSent`));
                onCancel();
            })
            .catch((error) => {
                notifications.showError(error.response?.data);
                setBusy(false);
            });
    };

    return (
        <form noValidate autoComplete="off" onSubmit={onReset}>
            <AlTextField
                fullWidth
                autoFocus
                placeholder={t('placeholder.login')}
                onChange={(e) => setLogin(e.target.value)}
                onBlur={() => setLoginTouched(true)}
                error={isLoginError}
                value={login}
                required={true}
                sx={{ mb: 2 }}
                disabled={busy}
                alTooltip={t('tooltip.resetForm.login')}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button type="submit" variant="contained" color="secondary" size="large" disabled={busy || !isValid}>
                    {t('button.reset')}
                </Button>
                <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={onCancel}
                    disabled={busy}
                >
                    {t('button.cancel')}
                </Button>
            </Box>
        </form>
    );
};

export { ResetForm };
