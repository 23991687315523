import React from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import css from './BookingDetailsForm.module.css';
import { AlTextField } from '../../../common/components/AlTextField';
import { AlSelectField } from '../../../common/components/AlSelectField';

const UserAddress = ({ booking, onChange, bookingError, touchBooking, disabled, dictionary }) => {
    const { t, i18n } = useTranslation();

    const countries =
        dictionary &&
        dictionary.countries.map((country) => {
            return {
                id: country.id,
                name_de: country.nameDE,
                name_en: country.name,
            };
        });

    return (
        <>
            <div className={css.row}>
                <div className={css.item}>
                    <AlTextField
                        fullWidth
                        name="user.address.street"
                        onChange={onChange}
                        value={booking.user.address.street || ''}
                        placeholder={t('placeholder.street')}
                        alTooltip={t('tooltip.user.addressStreet')}
                        onBlur={touchBooking}
                        error={bookingError('user.address.street')}
                        required={true}
                        disabled={disabled}
                    />
                </div>
                <div className={css.item}>
                    <AlTextField
                        fullWidth
                        name="user.address.zip"
                        onChange={onChange}
                        value={booking.user.address.zip || ''}
                        placeholder={t('zip')}
                        alTooltip={t('tooltip.user.addressZip')}
                        onBlur={touchBooking}
                        error={bookingError('user.address.zip')}
                        required={true}
                        disabled={disabled}
                    />
                </div>
            </div>
            <div className={css.row}>
                <div className={css.item}>
                    <AlTextField
                        fullWidth
                        name="user.address.place"
                        onChange={onChange}
                        value={booking.user.address.place || ''}
                        placeholder={t('city')}
                        alTooltip={t('tooltip.user.addressPlace')}
                        onBlur={touchBooking}
                        error={bookingError('user.address.place')}
                        required={true}
                        disabled={disabled}
                    />
                </div>
                {/*TODO remove style={{display: "none"} to have AIR-898 feature*/}
                <div className={css.item} style={{ display: 'none' }}>
                    <AlSelectField
                        fullWidth
                        name="user.address.countryId"
                        onChange={onChange}
                        value={booking.user.address.countryId}
                        placeholder={t('country')}
                        alTooltip={t('tooltip.user.country')}
                        onBlur={touchBooking}
                        error={bookingError('user.address.country')}
                        required={true}
                        disabled={disabled}
                    >
                        {countries.map((country, key) => (
                            <MenuItem value={country.id} key={key}>
                                {country[`name_${i18n.language}`]}
                            </MenuItem>
                        ))}
                    </AlSelectField>
                </div>
            </div>
        </>
    );
};

export { UserAddress };
