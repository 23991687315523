import { simpleRequest } from './request';

const CompanyService = {
    search: (query) =>
        simpleRequest({
            method: 'get',
            url: '/company/search-by-fields',
            params: { q: query },
        }),

    getByName: (name) =>
        simpleRequest({
            method: 'get',
            url: '/company/search',
            params: { name },
        }),
};

export { CompanyService };
