import { DateTime } from 'luxon';
import { GERMAN_TIMEZONE } from '../../constants';

// transfer is late when it is booked: a) for the same day; b) for the next day but booked after 12PM today;
const isTransferLate = (time, date, currentDate) => {
    if (!time || !date || !currentDate) {
        return false;
    }

    const currentDateTime = DateTime.fromISO(currentDate, { setZone: true });

    const isNowAM = currentDateTime.c.hour < 12;
    const transferDateTime = DateTime.fromISO(date + 'T' + time, {
        zone: GERMAN_TIMEZONE,
    });

    const isBookingForToday = transferDateTime.hasSame(currentDateTime, 'day');
    const isBookingForTomorrow = transferDateTime.hasSame(currentDateTime.plus({ day: 1 }), 'day');

    return isBookingForToday || (!isNowAM && isBookingForTomorrow);
};

export { isTransferLate };
