const currency = (amount) => {
    let formatted = ''; //default value

    if (amount !== undefined)
        formatted = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount);

    return formatted;
};

export { currency };
