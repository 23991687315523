import { cancellableRequest } from './request';

const DictionaryService = {
    get() {
        const dictionary = this.getDictionary();
        const luggages = this.getLuggages();
        const childSeats = this.getChildSeats();
        const services = this.getServices();
        const countries = this.getCountries();
        const requests = Promise.all([dictionary, luggages, childSeats, services, countries]).then(
            ([dictionary, luggages, childSeats, services, countries]) => {
                return {
                    ...dictionary,
                    luggages,
                    childSeats,
                    services,
                    countries,
                };
            },
        );
        requests.cancel = () => {
            dictionary.cancel();
            luggages.cancel();
            childSeats.cancel();
            services.cancel();
        };
        return requests;
    },
    getDictionary: () => cancellableRequest({ method: 'get', url: '/dictionary' }),
    getLuggages: () => cancellableRequest({ method: 'get', url: '/extras/extra-luggage' }),
    getChildSeats: () => cancellableRequest({ method: 'get', url: '/extras/child-seats' }),
    getServices: () => cancellableRequest({ method: 'get', url: '/extras/services' }),
    getCountries: () => cancellableRequest({ method: 'get', url: '/country' }),
};

export { DictionaryService };
