import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { PageTitle } from '../../common/components/PageTitle';

const BookingThankYou = () => {
    const { t } = useTranslation();

    return (
        <>
            <PageTitle sx={{ pt: 8 }}>{t('booking.thanks.title')}</PageTitle>
            <Box sx={(theme) => theme.mixins.box(theme)}>
                <Box sx={{ pt: 0.5, pb: 1 }}>
                    <Typography variant="h5" sx={{ mb: 3, textAlign: 'center' }}>
                        {t('booking.thanks.title2')}
                    </Typography>
                    <Typography variant="body1">{t('booking.thanks.itemsTitle')}</Typography>
                    <ol>
                        <Typography variant="body1" component="li" sx={{ mb: 1 }}>
                            {t('booking.thanks.item1')}
                        </Typography>
                        <Typography variant="body1" component="li" sx={{ mb: 1 }}>
                            {t('booking.thanks.item2')}
                        </Typography>
                        <Typography variant="body1" component="li" sx={{ mb: 1 }}>
                            {t('booking.thanks.item3')}
                        </Typography>
                    </ol>
                </Box>
            </Box>
        </>
    );
};

export { BookingThankYou };
