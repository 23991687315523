import React, { useEffect, useRef, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Clear from '@mui/icons-material/Clear';
import HomeIcon from '@mui/icons-material/Home';
import Popper from '@mui/material/Popper';
import { Box, CircularProgress, Typography } from '@mui/material';
import { faMapMarkerAlt, faRoad } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { InputRow } from '../InputRow';
import { PlacesService } from '../../../../api/PlacesService';
import { AIRPORTS } from '../airports';
import { AlTextField } from '../../../../common/components/AlTextField';
import { catchCallback } from '../../../../api/request';
import { GERMANY } from '../../../../constants';
import { listItemLabel } from './listItemLabel';
import { buildListGroups } from './buildListGroups';
import { ENV } from '../../../../env';
import axios from 'axios';

const AddressFields = ({
    transfer,
    updateTransfer,
    name,
    touchTransfer,
    transferError,
    disabled,
    dictionary,
    user,
}) => {
    const { t, i18n } = useTranslation();

    const address = transfer[name];
    const needStreetNumber =
        !!address.id &&
        AIRPORTS.every((airportCode) => airportCode !== address.id) &&
        address.street &&
        !address.streetNumber;
    const autoFocus = name === 'addressFrom';
    const placeValue = address.placeValue;
    const [listGroups, setListGroups] = useState([]);
    const [listAnchorEl, setListAnchorEl] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
    const listInputHostEl = useRef(null);
    const listInputEl = useRef(null);
    const listPopperEl = useRef(null);
    const open = Boolean(listAnchorEl);
    const listId = open ? `popper-${name}` : undefined;
    const selected = Boolean(address.id);

    const listInputOnFocus = () => {
        setListAnchorEl(listInputEl.current);
    };

    const listInputOnClick = () => {
        setListAnchorEl(listInputEl.current);
    };

    const listInputOnBlur = () => {
        if (!disabled && !selected) {
            setErrorMessage('booking.errorNoValueSelected');
        }

        if (!address.id) {
            updateTransfer({
                name,
                value: {
                    ...address,
                    zip: '',
                    place: address.placeValue,
                },
            });
        }

        setListAnchorEl(null);
    };

    const listInputChange = (e) => {
        updateTransfer({
            name,
            value: {
                id: '',
                zip: address.zip || '',
                place: address.place || '',
                street: AIRPORTS.includes(address.id) ? '' : address.street || '',
                name: '',
                phone: '',
                countryId: GERMANY,
                placeValue: e.target.value,
            },
        });
        setListAnchorEl(listInputEl.current);
    };

    const listMoveOffsetMap = { 38: -1, 40: 1, 33: -10, 34: 10 };

    const listInputOnKeyDown = (e) => {
        switch (e.keyCode) {
            case 38: // arrow up
            case 40: // arrow down
            case 33: // page up
            case 34: // page down
                if (!listAnchorEl) {
                    setListAnchorEl(listInputEl.current);
                } else {
                    listMoveCurrentIndex(listPopperEl.current, listMoveOffsetMap[e.keyCode]);
                }
                break;
            case 27: // esc
                setListAnchorEl(null);
                break;
            case 13: // enter
                if (listAnchorEl) {
                    listSelectCurrentIndex(listPopperEl.current, e);
                }
                break;
            default:
                break;
        }
    };

    const listOnSelect = (item) => {
        // Case Default Airport Munich or Airport Nuremberg
        if (!item.placeId) {
            updateTransfer({
                name,
                value: {
                    ...item,
                    countryId: GERMANY,
                    placeValue: item.placeValue || item.label,
                    label: undefined,
                },
            });

            setListAnchorEl(null);
            setErrorMessage(null);

            return;
        }

        // Case Google Place API suggestion Response
        PlacesService.getPlace(item.placeId).then((data) => {
            // set error message if there is no street and no place id in database
            // -- is set in the backend if the street is missing in the google place api response
            if (data.street === '--') {
                // check if googlePlaceId exists in db
                PlacesService.searchPlaceId(item.placeId).then((dbResult) => {
                    // if placeId exists in db, update data
                    if (!dbResult) {
                        // temporary logger to log missing street entries
                        // add gplace id to database if requested often
                        if (ENV.APP_LOGGER_URL) {
                            axios({
                                method: 'post',
                                url: ENV.APP_LOGGER_URL,
                                data: { error: 'Missing street entry for placeId: ' + item.placeId },
                            }).catch((error) => {
                                console.error('Error while logging missing street entry', error);
                            });
                        }

                        setErrorMessage('booking.errorStreetMissing');
                        updateTransfer({
                            name,
                            value: {
                                ...address,
                                placeValue: '',
                            },
                        });
                        setListAnchorEl(null);
                        return;
                    } else {
                        updateTransfer({
                            name,
                            value: {
                                ...data,
                                countryId: GERMANY,
                                placeValue: item.placeValue || item.label,
                                gPlaceId: item.placeId,
                                label: undefined,
                            },
                        });
                    }
                });
            } else {
                updateTransfer({
                    name,
                    value: {
                        ...data,
                        name: data.name !== data.street ? data.name : '',
                        countryId: GERMANY,
                        placeValue: item.placeValue || item.label,
                        gPlaceId: item.placeId,
                        label: undefined,
                    },
                });
            }

            setErrorMessage(null);
            setListAnchorEl(null);
        });
    };

    const listOnMouseDown = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const listClear = () => {
        updateTransfer({
            name,
            value: {
                countryId: GERMANY,
            },
        });
    };

    const selectHome = () => {
        const item = {
            place: user.address.place,
            street: user.address.street,
            zip: user.address.zip,
        };
        updateTransfer({
            name,
            value: {
                ...item,
                placeValue: listItemLabel(item),
                countryId: GERMANY,
            },
        });
    };

    useEffect(() => {
        const search = (q) => {
            const placesRequest = PlacesService.search(q, i18n.language);

            // Set a timeout to show the loading spinner if the request takes more than 1 second
            const timeoutId = setTimeout(() => {
                setShowLoadingSpinner(true);
            }, 1500);

            placesRequest
                .then((placesData) => {
                    clearTimeout(timeoutId);
                    setShowLoadingSpinner(false);
                    setListGroups(buildListGroups({ t, placesData }));
                })
                .catch(
                    catchCallback((error) => {
                        clearTimeout(timeoutId);
                        setShowLoadingSpinner(false);
                        console.error('Places have not been loaded', error);
                    }),
                );

            return () => {
                clearTimeout(timeoutId);
                placesRequest.cancel();
            };
        };
        return search(placeValue);
    }, [placeValue, dictionary, t, i18n.language]);

    const updatePlaceAccordingToLanguage = () => {
        if (!address.id || !AIRPORTS.includes(address.id)) {
            return;
        }
        updateTransfer({
            name: `${name}.placeValue`,
            value: t(`airport.${address.id}`),
        });
    };

    useEffect(updatePlaceAccordingToLanguage, [i18n.language]);

    const homeButton =
        (user && (
            <IconButton onClick={selectHome} disabled={disabled}>
                <HomeIcon sx={{ fontSize: '28px' }} />
            </IconButton>
        )) ||
        null;

    const updateStreetNumber = (e) => {
        const streetNumber = e.target.value;

        updateTransfer({
            name,
            value: {
                ...address,
                // to update street value with every typing of number
                street: `${address.street.split(',')[0]}, ${streetNumber}`,
                number: streetNumber,
            },
        });
    };

    return (
        <>
            <InputRow icon={faMapMarkerAlt} iconButton={homeButton} errorMessage={errorMessage}>
                <Box sx={{ position: 'relative' }} ref={listInputHostEl}>
                    <AlTextField
                        alRef={listInputEl}
                        fullWidth
                        name={`${name}.placeValue`}
                        placeholder={t(`placeholder.${name}`)}
                        error={transferError(`${name}.placeValue`)}
                        alTooltip={t(`tooltip.${name}`)}
                        required={true}
                        disabled={disabled || selected}
                        onChange={listInputChange}
                        onFocus={listInputOnFocus}
                        onClick={() => !disabled && !selected && listInputOnClick()}
                        onBlur={listInputOnBlur}
                        onKeyDown={listInputOnKeyDown}
                        value={placeValue || ''}
                        autoFocus={autoFocus}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onMouseDown={listOnMouseDown}
                                        onClick={listClear}
                                        disabled={disabled}
                                        size="small"
                                    >
                                        <Clear />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Popper
                        sx={{
                            backgroundColor: 'background.paper',
                            zIndex: 2,
                            width: '100%',
                            borderRadius: '4px',
                            marginTop: '3px',
                            overflow: 'auto',
                            boxSizing: 'border-box',
                            boxShadow: '1px 1px 4px rgba(0, 0, 0, 04)',
                            maxHeight: '50vh', // to prevent jumping from up to down at the first opening
                        }}
                        id={listId}
                        open={open}
                        anchorEl={listAnchorEl}
                        container={listInputHostEl.current}
                        placement="bottom-start"
                        onMouseDown={listOnMouseDown}
                        ref={listPopperEl}
                    >
                        {listGroups.map((group, groupKey) => {
                            const airportGroup = group.label === '';

                            // Show loading spinner while loading place data but still show airport group
                            if (!airportGroup && showLoadingSpinner) {
                                return (
                                    <React.Fragment key="loading">
                                        <Typography variant="subtitle2" sx={{ pt: 1, pl: 1, pb: 0.5, fontWeight: 600 }}>
                                            {group.label}
                                        </Typography>
                                        <Box
                                            key={groupKey}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                padding: '10px',
                                            }}
                                        >
                                            <Typography variant="subtitle2">
                                                <CircularProgress size={20} />
                                            </Typography>
                                        </Box>
                                    </React.Fragment>
                                );
                            }

                            return (
                                <React.Fragment key={groupKey}>
                                    {(group.label && (
                                        <Typography variant="subtitle2" sx={{ pt: 1, pl: 1, pb: 0.5, fontWeight: 600 }}>
                                            {group.label}
                                        </Typography>
                                    )) ||
                                        null}
                                    <Box
                                        component="ul"
                                        sx={(theme) => ({
                                            margin: 0,
                                            padding: 0,
                                            listStyle: 'none',

                                            '& li': {
                                                padding: '7px 14px',
                                            },

                                            '& li:hover, & li[data-focus="true"]': {
                                                backgroundColor: theme.palette.action.hover,
                                                cursor: 'pointer',
                                            },

                                            '& li:active': {
                                                backgroundColor: theme.palette.action.focus,
                                            },
                                        })}
                                    >
                                        {group.items.map((listItem, key) => (
                                            <Typography
                                                variant="subtitle2"
                                                component="li"
                                                key={key}
                                                onClick={() => listOnSelect(listItem)}
                                            >
                                                {listItem.label}
                                            </Typography>
                                        ))}
                                    </Box>
                                </React.Fragment>
                            );
                        })}
                    </Popper>
                </Box>
            </InputRow>
            {needStreetNumber && (
                <InputRow icon={faRoad}>
                    <AlTextField
                        fullWidth
                        name={`${name}.number`}
                        type="text"
                        placeholder={t('placeholder.streetNumber')}
                        onChange={updateStreetNumber}
                        onBlur={touchTransfer}
                        error={transferError(`${name}.number`)}
                        value={transfer[name].number || ''}
                        alTooltip={t(`tooltip.${name}Street`)}
                        required={true}
                        // disabled={disabled || !!transfer[name].streetNumber}
                    />
                </InputRow>
            )}
        </>
    );
};

function listMoveCurrentIndex(listEl, offset) {
    const items = listEl.querySelectorAll('li');

    if (!items.length) {
        return;
    }

    const currentItem = listEl.querySelector('li[data-focus="true"]');

    if (!currentItem) {
        items[0].setAttribute('data-focus', 'true');
        listEl.setAttribute('data-index', 0);
        return;
    }

    let currentIndex = Number(listEl.getAttribute('data-index'));

    items[currentIndex].removeAttribute('data-focus');
    currentIndex += offset;
    currentIndex = Math.max(0, Math.min(items.length - 1, currentIndex));
    items[currentIndex].setAttribute('data-focus', 'true');
    items[currentIndex].scrollIntoView({ block: 'center' });
    listEl.setAttribute('data-index', currentIndex);
}

function listSelectCurrentIndex(listEl, e) {
    const currentItem = listEl.querySelector('li[data-focus="true"]');

    if (!currentItem) {
        return;
    }

    currentItem.click();
    e.stopPropagation();
    e.preventDefault();
}

export { AddressFields };
