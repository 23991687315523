const TRANSFER = {
    TO_MUNICH: 1,
    FROM_MUNICH: 2,
    TO_NUREMBERG: 3,
    FROM_NUREMBERG: 4,
    SPECIAL: 5,
};

const BACK_TRANSFER = {
    [TRANSFER.TO_MUNICH]: TRANSFER.FROM_MUNICH,
    [TRANSFER.FROM_MUNICH]: TRANSFER.TO_MUNICH,
    [TRANSFER.TO_NUREMBERG]: TRANSFER.FROM_NUREMBERG,
    [TRANSFER.FROM_NUREMBERG]: TRANSFER.TO_NUREMBERG,
};

export { TRANSFER, BACK_TRANSFER };
