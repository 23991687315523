import React from 'react';
import defaultCarImage from '../../assets/images/car.jpeg';
import { Box } from '@mui/material';

const CarImage = ({ url }) => {
    const image = url || defaultCarImage;

    return (
        <Box
            component="span"
            sx={{
                display: 'inline-block',
                width: '166px',
                height: '99px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: '100% auto',
                backgroundImage: `url("${image}")`,
            }}
        />
    );
};

export { CarImage };
