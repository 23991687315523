import React, { useEffect, useRef, useState } from 'react';
import css from './BookingDetailsForm.module.css';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Accordion from '@mui/material/Accordion';
import { Typography } from '@mui/material';
import { ExpansionPanelSummary } from '../../../common/components/ExpansionPanelSummary';

const CreateAccount = ({ booking, onCheckboxChange }) => {
    const { t } = useTranslation();
    const firstRef = useRef(null);
    const [isExpanded, setExpanded] = useState(false);

    useEffect(() => {
        if (!isExpanded) {
            return;
        }
        firstRef.current.focus();
    }, [isExpanded]);

    return (
        <Accordion onChange={(_, expanded) => setExpanded(expanded)}>
            <ExpansionPanelSummary text="booking.createAccount" />
            <div className={css.expansionBody}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={booking.user.register}
                            onChange={onCheckboxChange}
                            name="user.register"
                            inputProps={{ autoComplete: 'off' }}
                            inputRef={firstRef}
                        />
                    }
                    label={<Typography>{t('booking.register')}</Typography>}
                />
            </div>
        </Accordion>
    );
};

export { CreateAccount };
