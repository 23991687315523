import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import moment from 'moment';
import { faCalendar, faClock } from '@fortawesome/free-solid-svg-icons';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { InputRow } from './InputRow';
import { Time } from '../../../common/components/Time';

const DetailA2BFields = ({ transfer, onDateChange, onTimeChange, touchTransfer, transferError }) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);

    const dateValue = (dateString) => {
        return (dateString && moment(dateString, 'YYYY-MM-DD')) || dateString;
    };

    // to start the week on Monday
    moment.updateLocale('en', {
        week: {
            dow: 1,
        },
    });

    const minDate = moment().add(1, 'd');

    return (
        <>
            <InputRow icon={faCalendar}>
                <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    <MobileDatePicker
                        open={open}
                        onOpen={() => {
                            touchTransfer({ name: 'tripDate' });
                            setOpen(true);
                        }}
                        onClose={() => setOpen(false)}
                        sx={{ width: '100%' }}
                        name="tripDate"
                        format="DD.MM.YYYY"
                        value={dateValue(transfer.tripDate)}
                        minDate={minDate}
                        onChange={(date) => {
                            onDateChange({ date, name: 'tripDate' });
                            setOpen(false);
                        }}
                        required
                        slotProps={{
                            textField: {
                                error: transferError('tripDate'),
                                placeholder: t('a2b.date'),
                            },
                        }}
                    />
                    <Box sx={{ position: 'absolute', right: '22px', top: '2px' }}>*</Box>
                    <Tooltip title={t('a2b.date')} placement="right" enterTouchDelay={0} leaveTouchDelay={3000}>
                        <HelpOutlineIcon
                            sx={{
                                position: 'absolute',
                                p: 0.5,
                                right: 0,
                                top: 0,
                                fontSize: '16px',
                                color: 'text.secondary',
                            }}
                        />
                    </Tooltip>
                </Box>
            </InputRow>
            <InputRow icon={faClock}>
                <Time
                    error={transferError('tripTime')}
                    value={transfer.tripTime}
                    onBlur={() => touchTransfer({ name: 'tripTime' })}
                    onChange={(time) => onTimeChange({ time, name: 'tripTime' })}
                    minutesTooltip={t('tooltip.tripTime')}
                    required={true}
                    label={t('label.tripTime')}
                />
            </InputRow>
        </>
    );
};

export { DetailA2BFields };
