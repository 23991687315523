import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Box, Typography } from '@mui/material';
import { AlSelectField } from './AlSelectField';

const numberFormat = (number) => {
    const intNumber = parseInt(number || '0', 10);
    return (intNumber < 10 ? '0' : '') + String(intNumber);
};

const Time = ({ value, onChange, onBlur, error, required, hoursTooltip, minutesTooltip, label }) => {
    let [hours, minutes] = (value || '').split(':');
    [hours, minutes] = [hours, minutes].map(numberFormat);

    const onHoursChange = (e) => {
        onChange(`${e.target.value}:${minutes}`);
    };

    const onMinutesChange = (e) => {
        onChange(`${hours}:${e.target.value}`);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',

                '& > *': {
                    flexBasis: 0,
                },

                '& > *:not(:first-of-type)': {
                    marginLeft: '10px',
                },
            }}
        >
            <AlSelectField
                value={hours || 0}
                onChange={onHoursChange}
                onBlur={onBlur}
                alTooltip={hoursTooltip}
                error={error}
                required={required}
            >
                {[...Array(24)]
                    .map((_, key) => numberFormat(key))
                    .map((key) => (
                        <MenuItem value={key} key={key}>
                            {key}
                        </MenuItem>
                    ))}
            </AlSelectField>
            <AlSelectField
                value={minutes || 0}
                onChange={onMinutesChange}
                onBlur={onBlur}
                alTooltip={minutesTooltip}
                alTooltipOut
                error={error}
                required={required}
            >
                {[...Array(60)]
                    .map((_, key) => numberFormat(key))
                    .map((key) => (
                        <MenuItem value={key} key={key}>
                            {key}
                        </MenuItem>
                    ))}
            </AlSelectField>
            <Typography variant="body2" sx={{ pt: 0.75, pl: 2, flexGrow: 1, color: 'text.secondary', lineHeight: 1.2 }}>
                {label}
            </Typography>
        </Box>
    );
};

export { Time };
