import { AIRPORTS, AIRPORTS_ADDRESS } from '../airports';

function buildListGroups({ t, placesData }) {
    return [
        {
            label: '',
            items: AIRPORTS.map((id) => ({
                ...AIRPORTS_ADDRESS[id],
                id,
                label: t(`airport.${id}`),
            })),
        },
        {
            label: t('city'),
            items: placesData.map((item) => ({ ...item, label: item.text })),
        },
    ].filter((group) => group.items.length);
}

export { buildListGroups };
