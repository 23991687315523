import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useTranslation } from 'react-i18next';
import { LoginForm } from '../LoginForm/LoginForm';
import { ResetForm } from '../ResetForm/ResetForm';
import { useEffect } from 'react';

const AuthDialog = ({ open, onClose, setUser, notifications }) => {
    const { t } = useTranslation();
    const [reset, setReset] = useState(false);

    const onResetOpen = () => {
        setReset(true);
    };

    const handleOnClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }

        onClose();
    };

    useEffect(() => {
        if (open) {
            setReset(false);
        }
    }, [open]);

    return (
        <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={handleOnClose}>
            {!reset && (
                <>
                    <DialogTitle>{t('loginForm.title')}</DialogTitle>
                    <DialogContent>
                        <LoginForm
                            setUser={setUser}
                            onCancel={onClose}
                            notifications={notifications}
                            onReset={onResetOpen}
                        />
                    </DialogContent>
                </>
            )}
            {reset && (
                <>
                    <DialogTitle>{t('resetForm.title')}</DialogTitle>
                    <DialogContent>
                        <ResetForm onCancel={onClose} notifications={notifications} />
                    </DialogContent>
                </>
            )}
        </Dialog>
    );
};

export { AuthDialog };
