const setIn = (obj, keys, value) => {
    const path = typeof keys === 'string' ? keys.split('.') : keys;
    const destObj = path.slice(0, -1).reduce((o, key) => {
        if (!o[key]) {
            o[key] = {};
        }
        return o[key];
    }, obj);
    destObj[path.slice(-1)] = value;
    return obj;
};

export { setIn };
