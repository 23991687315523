import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { AppBar } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as Logo } from '../../../assets/images/airportliner_logo.svg';
import de from '../../../assets/images/de.png';
import uk from '../../../assets/images/uk.png';
import css from './Header.module.css';

const Header = ({ onLogin, onLogout, user }) => {
    const { i18n, t } = useTranslation();

    const userButtons = (() => {
        return user ? (
            <div className={css.userAccess}>
                <Button
                    component={Link}
                    to="/profile"
                    sx={{
                        maxWidth: {
                            xs: '200px',
                            sm: '250px',
                            md: '400px',
                        },
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        justifyContent: 'flex-start',
                        mr: 0.5,
                        color: 'primary.contrastText',
                        textTransform: 'capitalize',
                        fontWeight: 400,
                    }}
                    data-cy="profileButton"
                >
                    {`${user.firstName} ${user.lastName}`}
                </Button>

                <IconButton onClick={onLogout} color="inherit" edge="start">
                    <LogoutIcon sx={{ color: 'primary.contrastText' }} fontSize="small" />
                </IconButton>
            </div>
        ) : (
            <Button
                onClick={onLogin}
                sx={{
                    mr: {
                        xs: 'auto',
                        sm: 2,
                    },
                    color: 'primary.contrastText',
                    textTransform: 'capitalize',
                    fontWeight: 400,
                }}
                data-cy="loginButton"
                endIcon={<AccountCircleIcon />}
            >
                {t('button.login')}
            </Button>
        );
    })();

    return (
        <AppBar position="static">
            <div className={css.host}>
                {/*in case we want to add a target=_blank, we need to consider this:
                https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/*/}
                <a href={getLinkToAirportLinerHomepage(i18n)}>
                    <Logo className={css.logo} title={t('appTitle')} />
                </a>

                <div className={css.buttons}>
                    {userButtons}
                    <LangItem lng="de" src={de} />
                    <LangItem lng="en" src={uk} />
                </div>
            </div>
        </AppBar>
    );
};

/**
 * Put together the correct link to the airportLiner homepage.
 * Take the language object and convert the language setting to the proper string needed for the website link.
 */
const getLinkToAirportLinerHomepage = (i18n) => {
    let linkLanguage;
    let languageFromI18N = i18n.language.split('-');

    if (languageFromI18N[0] === 'de') {
        linkLanguage = '';
    } else linkLanguage = 'en';

    return `https://www.airportliner.com/${linkLanguage}`;
};

const LangItem = ({ lng, src }) => {
    const { i18n } = useTranslation();

    return (
        <IconButton color="inherit" onClick={() => i18n.changeLanguage(lng)}>
            <img src={src} alt={lng} width="24" height="24" />
        </IconButton>
    );
};

export { Header };
