import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import { findLang } from '../models/lang';

const Footer = () => {
    const { t, i18n } = useTranslation();

    const links = {
        de: {
            imprint: 'https://www.airportliner.com/impressum/',
            privacy: 'https://www.airportliner.com/datenschutzerklaerung/',
        },
        en: {
            imprint: 'https://www.airportliner.com/en/legal/',
            privacy: 'https://www.airportliner.com/en/privacy/',
        },
    };

    return (
        <Box
            sx={{
                display: 'flex',
                padding: '8px 16px',
                backgroundColor: 'primary.main',
                justifyContent: 'center',
            }}
        >
            <Button
                href={links[findLang(i18n.language).i18nCode].imprint}
                sx={{
                    textTransform: 'capitalize',
                    color: 'primary.contrastText',
                    fontWeight: 400,
                }}
                rel="noopener noreferrer"
                target="_blank"
            >
                {t('imprint')}
            </Button>
            <Button
                href={links[findLang(i18n.language).i18nCode].privacy}
                sx={{
                    textTransform: 'capitalize',
                    color: 'primary.contrastText',
                    fontWeight: 400,
                }}
                rel="noopener noreferrer"
                target="_blank"
            >
                {t('privacy')}
            </Button>
        </Box>
    );
};

export { Footer };
