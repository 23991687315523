import React from 'react';
import css from './WithSidebar.module.css';

const WithSidebar = ({ content, sidebar }) => {
    return (
        <div className={css.withSidebar}>
            <div className={css.contentBox}>{content}</div>
            <div className={css.sidebarBox}>{sidebar}</div>
        </div>
    );
};

export { WithSidebar };
