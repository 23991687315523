import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, AccordionSummary } from '@mui/material';

const ExpansionPanelSummary = ({ text, children }) => {
    const { t } = useTranslation();

    return (
        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'primary.contrastText' }} />}>
            <Stack sx={{ flexDirection: { sm: 'row' }, alignItems: { sm: 'center' } }}>
                <Typography
                    variant="h6"
                    sx={{
                        whiteSpace: 'nowrap',
                        pb: 0.25,
                    }}
                >
                    {t(text)}
                </Typography>
                {children}
            </Stack>
        </AccordionSummary>
    );
};

export { ExpansionPanelSummary };
