import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { currency } from '../../../common/utils/currency';

const TotalPrice = ({ price }) => {
    const { t } = useTranslation();

    if (!price) {
        return null;
    }

    const total = price.totalPrice;
    const netAmount = currency(price.netto);
    const vat = Math.round(((price.totalPrice - price.netto) / price.netto) * 100);

    const secondTransfer = price.transfers[1];
    const items = price.transfers.map((transfer, i) => {
        return (
            <>
                <Typography variant="h6" sx={{ mt: 1, mb: 0.5 }}>
                    {t('booking.transfer')} {secondTransfer ? i + 1 : ''}
                </Typography>
                {['basePrice', 'distancePrice', 'luggagePrice', 'servicePrice', 'paymentSystemFee'].map(
                    (key) =>
                        // show luggage label always to clarify that some luggage is free
                        ((key === 'luggagePrice' || transfer[key] !== 0) && (
                            <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                <Typography variant="body1">{t(`booking.price.${key}`)}</Typography>
                                <Typography variant="body1">{currency(transfer[key])}</Typography>
                            </Box>
                        )) ||
                        null,
                )}
            </>
        );
    });

    return (
        <Paper sx={{ px: 2.5, pt: 2, pb: 2 }}>
            <Typography variant="h5" sx={{ textAlign: 'center' }}>
                {t('total-calculation')}
            </Typography>
            {items.map((item, i) => (
                <Fragment key={i}>{item}</Fragment>
            ))}
            <Divider sx={{ mt: 2, mb: 1.5 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6">{t('total')}</Typography>
                <Typography variant="h6">{currency(total)}</Typography>
            </Box>
            <Typography variant="caption" sx={{ display: 'block ' }}>
                {t('priceVat', { vat, netAmount })}
            </Typography>
        </Paper>
    );
};

export { TotalPrice };
