import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { PageTitle } from '../common/components/PageTitle';

const InfoPage = () => {
    const { code, number } = useParams();
    const { t } = useTranslation();

    // TODO: Info pages are the only place where the booking number is still shown to the customer
    // The number is retrieved from the Unzer return URL and is not stored in the state
    // To show the transfer numbers here we have to make a request to the backend to get the booking object and then get and display the transfer numbers

    return (
        <>
            <PageTitle sx={{ pt: 8 }}>{t(`infoPage.${code}.title`, { number })}</PageTitle>
            <Box sx={(theme) => theme.mixins.box(theme)}>
                <Box sx={{ pt: 0.5, pb: 1, maxWidth: '900px' }}>
                    <Typography variant="h5" sx={{ mb: 3, textAlign: 'center' }}>
                        {t(`infoPage.${code}.description`, { number })}
                    </Typography>
                    {t(`infoPage.${code}.description-detail`, { number }) !== `infoPage.${code}.description-detail` && (
                        <Typography variant="body1">{t(`infoPage.${code}.description-detail`, { number })}</Typography>
                    )}
                </Box>
            </Box>
        </>
    );
};

export { InfoPage };
