import { cancellableRequest, simpleRequest } from './request';

const BookingsService = {
    create: (data) =>
        cancellableRequest({
            method: 'post',
            url: '/booking',
            data,
        }),
    getList: (params) =>
        cancellableRequest({
            method: 'get',
            url: '/transfer/customer',
            params: { pageSize: 20, ...params },
        }),
    getCheckoutLink: (bookingNumber) =>
        cancellableRequest({
            method: 'get',
            url: `/payments/checkoutLink`,
            params: { bookingNumber },
        }),
    getCurrentDateTime: () =>
        simpleRequest({
            method: 'get',
            url: `/booking/current-date`,
        }),
};

export { BookingsService };
