import axios from './mainInstance';
import Axios from 'axios';
const CancelToken = Axios.CancelToken;

const simpleRequest = (options) => axios(options).then(({ data }) => data);

const cancellableRequest = (options) => {
    let cancel;
    const request = simpleRequest({
        ...options,
        cancelToken: new CancelToken((c) => {
            // An executor function receives a cancel function as a parameter
            cancel = c;
        }),
    });
    request.cancel = cancel;
    return request;
};

const catchCallback = (cb) => (error) => {
    if (Axios.isCancel(error)) {
        return;
    }

    cb(error);
};

export { cancellableRequest, catchCallback, simpleRequest };
