import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enGB from './enGB.json';
import deDE from './deDE.json';
import { ENV } from '../env';
import { findLang } from '../common/models/lang';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: enGB,
            de: deDE,
        },
        ns: ['translations'],
        defaultNS: 'translations',

        keySeparator: '.',

        fallbackLng: findLang().i18nCode,
        debug: ENV.APP_DEBUG,

        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
