import { useEffect, useState } from 'react';
import { BookingsService } from '../../api/BookingsService';

const THROTTLE_PERIOD = 30 * 1000;

const useServerDate = () => {
    const [serverDate, setServerDate] = useState();

    useEffect(() => {
        BookingsService.getCurrentDateTime().then((date) => setServerDate(date));

        const intervalId = setInterval(() => {
            BookingsService.getCurrentDateTime().then((date) => setServerDate(date));
        }, THROTTLE_PERIOD);

        return () => clearInterval(intervalId);
    }, []);

    return serverDate;
};

export { useServerDate };
