import React from 'react';
import { Typography } from '@mui/material';

const PageTitle = ({ children, sx }) => {
    return (
        <Typography
            variant="h3"
            sx={{
                mb: 3.5,
                padding: '0 16px',
                textAlign: 'center',
                ...sx,
            }}
        >
            {children}
        </Typography>
    );
};

export { PageTitle };
