const LANG = {
    de: { code: 'DE', name: 'german', i18nCode: 'de' },
    en: { code: 'EN', name: 'english', i18nCode: 'en' },
};

const findLang = (key) => {
    return (key && LANG[key]) || LANG.de;
};

const isLangDE = (code) => LANG.de.i18nCode === code;

export { LANG, findLang, isLangDE };
