import { cancellableRequest, simpleRequest } from './request';

const PlacesService = {
    search: (q, lang) =>
        cancellableRequest({
            method: 'get',
            url: `/places/autocomplete/${lang}`,
            params: { q },
        }),
    getPlace: (id) =>
        simpleRequest({
            method: 'get',
            url: `/places/${id}`,
        }),
    searchPlaceId: (id) =>
        simpleRequest({
            method: 'get',
            url: `/places/search-place-id`,
            params: { gPlaceId: id },
        }),
};

export { PlacesService };
