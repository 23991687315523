import React from 'react';
import { useTranslation } from 'react-i18next';
import { History } from '../History/History';
import { PageTitle } from '../../../common/components/PageTitle';

const ProfilePage = ({ dictionary }) => {
    const { t } = useTranslation();

    return (
        <>
            <PageTitle sx={{ pt: 8 }}>{t('profile.navigation.history')}</PageTitle>
            <History dictionary={dictionary} />
        </>
    );
};

export { ProfilePage };
