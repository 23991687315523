import { DateTime } from 'luxon';
import { GERMAN_TIMEZONE, SHORT_TERM_HOUR } from '../../constants';
import { TRANSFER } from '../../booking/models/transfer';

// check that current time is after 13:00 (german timezone)
const checkOrderLate = () => {
    return DateTime.now().setZone(GERMAN_TIMEZONE).c.hour >= SHORT_TERM_HOUR.FROM;
};

// check that day and time is for today or tomorrow
const checkBookingNextDay = (dateTime) => {
    const lastMinuteTo = DateTime.now().plus({ day: 1 }).endOf('day');

    return dateTime <= lastMinuteTo;
};

// check if one of transfers is short term
const checkShortTermTransfer = (transfer) => {
    let bookingDateTime;

    switch (transfer.typeId) {
        case TRANSFER.TO_MUNICH:
        case TRANSFER.TO_NUREMBERG:
            bookingDateTime = DateTime.fromISO(transfer.flight.date + 'T' + transfer.arrivalTime);
            break;

        case TRANSFER.FROM_MUNICH:
        case TRANSFER.FROM_NUREMBERG:
            bookingDateTime = DateTime.fromISO(transfer.flight.date + 'T' + transfer.flight.time);
            break;

        case TRANSFER.SPECIAL:
            bookingDateTime = DateTime.fromISO(transfer.tripDate + 'T' + transfer.tripTime);
            break;

        default:
            return false;
    }

    const isBookingNextDay = checkBookingNextDay(bookingDateTime);
    const isOrderLate = checkOrderLate();

    return isOrderLate && isBookingNextDay;
};

// check if booking is short term
const checkShortTermBooking = (transfers) => {
    return transfers.filter(Boolean).some(checkShortTermTransfer);
};

export { checkShortTermBooking };
