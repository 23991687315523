import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { DEFAULT_PAYMENT } from '../../../constants';

const DEFAULT_PAYMENTS = [DEFAULT_PAYMENT, 9, 15];
const ALL_PAYMENTS = [DEFAULT_PAYMENT, 9, 11, 15, 10];

const PaymentPart = ({ booking, onNumberChange, dictionary, user }) => {
    const { t } = useTranslation();
    const paymentMap = dictionary && new Map([...dictionary.paymentTypes.map((item) => [item.id, item])]);

    const allowedPaymentTypes =
        user && user.allowedPaymentTypes && user.allowedPaymentTypes.length ? user.allowedPaymentTypes : null;

    const payments = allowedPaymentTypes
        ? ALL_PAYMENTS.filter((paymentId) => allowedPaymentTypes.includes(paymentId))
        : DEFAULT_PAYMENTS;

    const paymentLabel = (id) => {
        const payment = paymentMap.get(id);
        const fee = Number(payment.fee);
        const label = t(`payment.${payment.name}`);
        if (!fee) {
            return label;
        }
        return `${label} (${String(fee).replace(/\./g, ',')}%)`;
    };

    useEffect(() => {
        if (!payments.includes(booking.paymentTypeId)) {
            onNumberChange({ name: 'paymentTypeId', value: payments[0] });
        }
    }, [booking.paymentTypeId, onNumberChange, payments]);

    return (
        <>
            <Typography variant="h5" sx={{ mt: 3, mb: 1, textAlign: 'center' }}>
                {t('choose-payment')}
            </Typography>
            <RadioGroup
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
                aria-label="payment types"
                name="paymentTypeId"
                value={booking.paymentTypeId}
                onChange={onNumberChange}
                row
            >
                {paymentMap &&
                    payments.map((id) => (
                        <FormControlLabel
                            key={id}
                            value={id}
                            control={<Radio inputProps={{ autoComplete: 'off' }} />}
                            label={<Typography>{paymentLabel(id)}</Typography>}
                        />
                    ))}
            </RadioGroup>
        </>
    );
};

export { PaymentPart };
