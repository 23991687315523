import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import { useState } from 'react';
import { BookingsService } from '../../../api/BookingsService';
import css from './History.module.css';
import { catchCallback } from '../../../api/request';
import { TRANSFER } from '../../../booking/models/transfer';
import Tooltip from '@mui/material/Tooltip';
import { findLang } from '../../../common/models/lang';
import { formattedDate } from '../../../common/utils/date';
import { getErrorMessageFromResponseError } from '../../../common/utils/getErrorMessageFromResponseError';

const PAGE_SIZE = 20;

let getTransfersCancel;

const History = ({ dictionary }) => {
    const [state, setState] = useState({
        transfers: [],
        nextPage: 0,
    });
    const { t, i18n } = useTranslation();
    const langCode = findLang(i18n.language).code;

    const transferState = (state) => {
        const dictionaryItem = dictionary?.transferStatuses.find((item) => item.id === state);
        return (dictionaryItem && t(`transferStatus.${dictionaryItem.name}`)) || '';
    };

    const paymentState = (transfer) => {
        const state = transfer.booking.payment?.state;
        const dictionaryItem = dictionary?.paymentStates.find((item) => item.id === state);
        return (dictionaryItem && t(`paymentState.${dictionaryItem.name}`)) || '';
    };

    const address = ({ zip, street, place, name }) => {
        return [[name, street, zip].filter(Boolean).join(', '), place].filter(Boolean).join(' ');
    };
    const addressFrom = (transfer) => {
        switch (transfer.typeId) {
            case TRANSFER.FROM_MUNICH:
                return t('airport.MUC');
            case TRANSFER.FROM_NUREMBERG:
                return t('airport.NUE');
            default:
                return (transfer.addressFrom && address(transfer.addressFrom)) || '-';
        }
    };
    const addressTo = (transfer) => {
        switch (transfer.typeId) {
            case TRANSFER.TO_MUNICH:
                return t('airport.MUC');
            case TRANSFER.TO_NUREMBERG:
                return t('airport.NUE');
            default:
                return (transfer.addressTo && address(transfer.addressTo)) || '-';
        }
    };

    const time = (transfer) => moment(transfer.transferTime || transfer.arrivalTime, 'HH:mm:ss').format('HH:mm');
    const dateTime = (transfer) => `${formattedDate(transfer.transferDate, langCode)} ${time(transfer)}`;

    const getTransfers = () => {
        if (getTransfersCancel) {
            getTransfersCancel();
            getTransfersCancel = null;
        }
        const request = BookingsService.getList({
            pageSize: PAGE_SIZE,
            page: state.nextPage,
        });
        request
            .then((result) => {
                setState({
                    count: result.count,
                    transfers: [...state.transfers, ...result.result],
                    nextPage: state.nextPage + 1,
                });
            })
            .catch(
                catchCallback((error) => {
                    console.error(getErrorMessageFromResponseError(error.response?.data));
                }),
            );
        return (getTransfersCancel = () => request.cancel());
    };

    return (
        <Box
            sx={(theme) => ({
                margin: '0 auto',
                padding: '0 5px 1px',
                maxWidth: '1260px',
                backgroundColor: theme.palette.grey[200],
                marginBottom: '60px',
            })}
        >
            <div className={css.rowHead}>
                <div className={css.cell}>
                    <Typography variant="h6">{t('profile.history.number')}</Typography>
                </div>
                <div className={css.cell}>
                    <Tooltip
                        title={t('profile.tooltip.historyDateTime')}
                        placement="right"
                        enterTouchDelay={0}
                        leaveTouchDelay={3000}
                    >
                        <Typography variant="h6">{t('profile.history.dateTime')}</Typography>
                    </Tooltip>
                </div>
                <div className={css.cell}>
                    <Tooltip
                        title={t('profile.tooltip.status')}
                        placement="right"
                        enterTouchDelay={0}
                        leaveTouchDelay={3000}
                    >
                        <Typography variant="h6">{t('profile.history.status')}</Typography>
                    </Tooltip>
                </div>
                <div className={css.cell}>
                    <Tooltip
                        title={t('profile.tooltip.paymentStatus')}
                        placement={'right'}
                        enterTouchDelay={0}
                        leaveTouchDelay={3000}
                    >
                        <Typography variant="h6">{t('profile.history.paymentStatus')}</Typography>
                    </Tooltip>
                </div>
                <div className={css.cell}>
                    <Typography variant="h6">{t('profile.history.from')}</Typography>
                </div>
                <div className={css.cell}>
                    <Typography variant="h6">{t('profile.history.to')}</Typography>
                </div>
            </div>
            <InfiniteScroll
                loadMore={getTransfers}
                hasMore={state.count === undefined || state.transfers.length < state.count}
            >
                {state.transfers.map((transfer, index) => (
                    <Box
                        key={index}
                        sx={{ backgroundColor: 'background.paper' }}
                        className={css.row}
                        data-cy="bookingRow"
                    >
                        <div className={css.cell}>
                            <Typography>{transfer.transferNumber}</Typography>
                        </div>
                        <div className={css.cell}>
                            <Typography>{dateTime(transfer)}</Typography>
                        </div>
                        <div className={css.cell}>
                            <Typography>{transferState(transfer.status)}</Typography>
                        </div>
                        <div className={css.cell}>
                            <Typography>{paymentState(transfer)}</Typography>
                        </div>
                        <div className={css.cell}>
                            <Typography>{addressFrom(transfer)}</Typography>
                        </div>
                        <div className={css.cell}>
                            <Typography>{addressTo(transfer)}</Typography>
                        </div>
                    </Box>
                ))}
            </InfiniteScroll>
        </Box>
    );
};

export { History };
