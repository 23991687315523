import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const InputRow = ({ children, icon, iconButton = null, errorMessage = null }) => {
    const { t } = useTranslation();

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                }}
            >
                <Box
                    component={FontAwesomeIcon}
                    icon={icon}
                    sx={(theme) => ({
                        fontSize: '24px',
                        marginRight: '12px',
                        color: theme.palette.grey[800],
                        flexShrink: 0,
                    })}
                    fixedWidth
                />
                <Box sx={{ flexGrow: 1 }}>{children}</Box>
                {iconButton}
            </Box>
            {errorMessage && (
                <Typography variant="caption" component="div" color="error" sx={{ mt: -0.75, ml: 5.5, mb: 1 }}>
                    {t(errorMessage)}
                </Typography>
            )}
        </>
    );
};

export { InputRow };
