import React, { useReducer, useEffect } from 'react';
import { Route, Switch, useRouteMatch, Redirect, useHistory } from 'react-router-dom';
import { BookingTransferForm } from '../BookingTransferForm/BookingTransferForm';
import { BookingCategoryList } from '../BookingCategoryList/BookingCategoryList';
import { BookingDetailsForm } from '../BookingDetailsForm/BookingDetailsForm';
import { BookingThankYou } from '../BookingThankYou';
import { BookingSuccess } from '../BookingSuccess';
import { bookingReducer, BOOKING_ACTION } from './bookingReducer';
import { bookingInitialValue } from './bookingInitialValue';
import { getIn } from '../../../common/utils/getIn';
import { transferFormValidationErrors } from '../BookingTransferForm/transferFormValidationErrors';
import { detailsFormValidationErrors } from '../BookingDetailsForm/detailsFormValidationErrors';
import { categoryListValidationErrors } from '../BookingCategoryList/categoryListValidationErrors';
import { useServerDate } from '../../../common/hooks/useServerTime';
import { BookingNavigation } from '../BookingNavigation';

const BookingPage = ({ user, setUser, notifications, dictionary }) => {
    const [booking, setBooking] = useReducer(bookingReducer, bookingInitialValue);
    const { path, url } = useRouteMatch();
    const history = useHistory();
    const serverDate = useServerDate();

    const firstTransfer = booking.transfers[0];
    const secondTransfer = booking.transfers[1];

    const updateBookingUser = () => {
        setBooking({
            type: BOOKING_ACTION.UPDATE_USER,
            value: user,
        });
    };

    const updateBooking = ({ target, name, value }) => {
        if (target) {
            ({ name, value } = target);
        }

        setBooking({
            type: BOOKING_ACTION.UPDATE,
            name,
            value,
            serverDate,
        });
    };

    const touchBooking = ({ target, name }) => {
        if (target) {
            ({ name } = target);
        }
        setBooking({
            type: BOOKING_ACTION.TOUCH,
            name,
        });
    };

    const [updateFirstTransfer, updateSecondTransfer] = [0, 1].map((index) => ({ target, name, value }) => {
        if (target) {
            ({ name, value } = target);
        }

        setBooking({
            type: BOOKING_ACTION.UPDATE,
            name: `transfers.${index}.${name}`,
            value,
            serverDate,
        });
    });

    const [touchFirstTransfer, touchSecondTransfer] = [0, 1].map((index) => ({ target, name }) => {
        if (target) {
            ({ name } = target);
        }
        setBooking({
            type: BOOKING_ACTION.TOUCH,
            name: `transfers.${index}.${name}`,
        });
    });

    const bookingErrorSrc = (validationErrors, name) =>
        Boolean(getIn(booking.touched, name) && getIn(validationErrors, name));
    const [firstTransferErrorSrc, secondTransferErrorSrc] = [0, 1].map(
        (index) => (validationErrors, name) =>
            Boolean(
                getIn(booking.touched, `transfers.${index}.${name}`) &&
                    getIn(validationErrors, `transfers.${index}.${name}`),
            ),
    );

    const resetBooking = () => {
        setBooking({ type: BOOKING_ACTION.RESET });
    };

    const addTransfer = () => {
        setBooking({ type: BOOKING_ACTION.ADD_TRANSFER });
    };

    const removeTransfer = () => {
        setBooking({ type: BOOKING_ACTION.REMOVE_TRANSFER });
    };

    const data = {
        booking,
        firstTransfer,
        secondTransfer,
    };

    const update = {
        updateBooking,
        updateFirstTransfer,
        updateSecondTransfer,
        resetBooking,
        addTransfer,
        removeTransfer,
    };

    const touch = {
        touchBooking,
        touchFirstTransfer,
        touchSecondTransfer,
    };

    const errorsSrc = {
        bookingErrorSrc,
        firstTransferErrorSrc,
        secondTransferErrorSrc,
    };

    const allValidationErrors = {
        transferForm: transferFormValidationErrors({
            firstTransfer,
            secondTransfer,
        }),
        categoryList: categoryListValidationErrors({ firstTransfer, secondTransfer }),
        detailsForm: detailsFormValidationErrors({ booking }),
    };

    useEffect(updateBookingUser, [user]);

    const validationRedirectCheck = (subPage) => {
        // check for IE usage and if so, redirect to error page
        const isIE = /*@cc_on!@*/ false || !!document.documentMode;
        if (isIE) history.push('/info/UnsupportedBrowserDetected');

        const category = [{ errors: allValidationErrors.transferForm, redirect: 'transfer' }];
        const details = [...category, { errors: allValidationErrors.categoryList, redirect: 'category' }];
        const map = { category, details };
        const error = map[subPage].find((rule) => rule.errors);
        return error && <Redirect to={`${url}/${error.redirect}`} />;
    };

    return (
        <>
            {dictionary && (
                <Switch>
                    <Route path={`${path}/transfer`}>
                        <BookingNavigation url={url} allValidationErrors={allValidationErrors} />
                        <BookingTransferForm
                            url={url}
                            data={data}
                            dictionary={dictionary}
                            update={update}
                            touch={touch}
                            user={user}
                            errorsSrc={errorsSrc}
                            validationErrors={allValidationErrors.transferForm}
                        />
                    </Route>
                    <Route path={`${path}/category`}>
                        <BookingNavigation url={url} allValidationErrors={allValidationErrors} />
                        {validationRedirectCheck('category') || (
                            <BookingCategoryList url={url} data={data} update={update} dictionary={dictionary} />
                        )}
                    </Route>
                    <Route path={`${path}/details`}>
                        <BookingNavigation url={url} allValidationErrors={allValidationErrors} />
                        {validationRedirectCheck('details') || (
                            <BookingDetailsForm
                                url={url}
                                data={data}
                                dictionary={dictionary}
                                update={update}
                                touch={touch}
                                errorsSrc={errorsSrc}
                                validationErrors={allValidationErrors.detailsForm}
                                notifications={notifications}
                                user={user}
                                setUser={setUser}
                            />
                        )}
                    </Route>
                    <Route path={`${path}/thank-you`}>
                        <BookingThankYou />
                    </Route>
                    <Route path={`${path}/success`}>
                        <BookingSuccess />
                    </Route>
                    <Redirect to={`${url}/transfer`} />
                </Switch>
            )}
        </>
    );
};

export { BookingPage };
