import React, { useEffect, useRef, useState } from 'react';
import css from './BookingDetailsForm.module.css';
import { useTranslation } from 'react-i18next';
import { Typography, Accordion, FormControlLabel, Tooltip, Checkbox } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ExpansionPanelSummary } from '../../../common/components/ExpansionPanelSummary';
import { extraPrice } from './extraPrice';
import { TRANSFER } from '../../models/transfer';
import { intersection } from '../../../common/utils/intersection';
import { getServiceTypeById } from '../../models/service-type';
import { SERVICES } from '../../../constants';

const getServiceTooltip = (code) =>
    [SERVICES.LATE, SERVICES.LATE_TO_MUNICH, SERVICES.LATE_FROM_MUNICH].includes(code) ? 'tooltip.lateBooking' : '';

// late booking service is always disabled as it is calculated automatically
const isServiceDisabled = (code) => [SERVICES.LATE, SERVICES.LATE_TO_MUNICH, SERVICES.LATE_FROM_MUNICH].includes(code);

const isLateBookingServiceEnabled = (transfer, secondTransfer, service) => {
    const isFirstTransferLate = transfer.extras[service];
    const isSecondTransferLate = secondTransfer && secondTransfer.extras[service];

    return Boolean(isFirstTransferLate || isSecondTransferLate);
};

// if a checkbox with service should be checked
const isServiceChecked = (code, transfer, secondTransfer) => {
    if ([SERVICES.LATE, SERVICES.LATE_TO_MUNICH, SERVICES.LATE_FROM_MUNICH].includes(code)) {
        // if late booking service is actual it should be always checked
        return isLateBookingServiceEnabled(transfer, secondTransfer, code);
    }

    return Boolean(transfer.extras[code]);
};

const isServiceAvailableHelper = (service, transfer, secondTransfer) => {
    const transferTypes = [transfer.typeId, secondTransfer && secondTransfer.typeId];
    const isToAirport = intersection([TRANSFER.TO_MUNICH, TRANSFER.TO_NUREMBERG], transferTypes).length > 0;
    const isFromAirport = intersection([TRANSFER.FROM_MUNICH, TRANSFER.FROM_NUREMBERG], transferTypes).length > 0;

    switch (service.internalCode) {
        case 'FC':
            return isToAirport || isFromAirport; // is this really needed? Isn't FC always available for AP transfers?
        case 'ARRIVE_EARLY':
        case 'ARRIVE_LATE':
            return isToAirport;
        case 'GATE':
            return isFromAirport;
        case SERVICES.LATE_TO_MUNICH:
            // this extra is being updated in booking reducer by every booking change
            return isLateBookingServiceEnabled(transfer, secondTransfer, SERVICES.LATE_TO_MUNICH);
        case SERVICES.LATE_FROM_MUNICH:
            // this extra is being updated in booking reducer by every booking change
            return isLateBookingServiceEnabled(transfer, secondTransfer, SERVICES.LATE_FROM_MUNICH);
        case SERVICES.LATE:
            // this extra is being updated in booking reducer by every booking change
            return isLateBookingServiceEnabled(transfer, secondTransfer, SERVICES.LATE);

        default:
            return false;
    }
};

const Services = ({ services, transfer, secondTransfer, updateTransfer }) => {
    const { t } = useTranslation();
    const firstRef = useRef(null);
    const [isExpanded, setExpanded] = useState(false);

    const onCheckboxChange = (e, value) => {
        const { name } = e.target;
        //convert to 1/0 for consistency with the orga tool
        updateTransfer({ value: Number(value), name: name });
    };

    const isServiceAvailable = (service) => {
        if (service.disabled) {
            return false;
        }

        return isServiceAvailableHelper(service, transfer, secondTransfer);
    };

    const filteredServices = services.filter(isServiceAvailable);

    const hasLateBookingService = !!filteredServices.find((service) =>
        [SERVICES.LATE, SERVICES.LATE_TO_MUNICH, SERVICES.LATE_FROM_MUNICH].includes(service.internalCode),
    );

    useEffect(() => {
        // to let а customer see a charge for late booking
        if (hasLateBookingService) {
            setExpanded(true);
        }
    }, []);

    if (!filteredServices.length) {
        return null;
    }

    return (
        <Accordion onChange={(_, expanded) => setExpanded(expanded)} expanded={isExpanded}>
            <ExpansionPanelSummary text="booking.addServices" />
            <div className={css.expansionBody}>
                <div className={css.grid} ref={firstRef}>
                    {filteredServices.map((service) => {
                        const { internalCode } = service;
                        const tooltip = getServiceTooltip(internalCode);

                        return (
                            <FormControlLabel
                                key={internalCode}
                                control={
                                    <Checkbox
                                        checked={isServiceChecked(internalCode, transfer, secondTransfer)}
                                        onChange={onCheckboxChange}
                                        name={`extras.${internalCode}`}
                                        inputProps={{ autoComplete: 'off' }}
                                        disabled={isServiceDisabled(internalCode)}
                                    />
                                }
                                label={
                                    <Typography sx={{ position: 'relative' }}>
                                        {t(`extras.${internalCode}`)}&nbsp;
                                        {extraPrice(service[getServiceTypeById(transfer.serviceTypeId)])}
                                        {tooltip && (
                                            <Tooltip
                                                title={t(tooltip)}
                                                placement="right"
                                                enterTouchDelay={0}
                                                leaveTouchDelay={3000}
                                            >
                                                <HelpOutlineIcon
                                                    sx={{
                                                        position: 'absolute',
                                                        p: 0.5,
                                                        top: '-10px',
                                                        right: '-20px',
                                                        fontSize: '16px',
                                                        color: 'text.secondary',
                                                    }}
                                                />
                                            </Tooltip>
                                        )}
                                    </Typography>
                                }
                            />
                        );
                    })}
                </div>
            </div>
        </Accordion>
    );
};

export { Services, isServiceAvailableHelper };
