import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import { AlTextField } from '../../../../common/components/AlTextField';
import { CompanyService } from '../../../../api/CompanyService';
import { CompanyAddress } from './CompanyAddress';

const CompanyField = styled(Box)`
    margin: 0 0 10px;
    width: 100%;
    max-width: 500px;
    min-width: auto;
`;

const RESET_COMPANY_DATA = { target: { name: 'company', value: { name: '' } } };

// format fata for showing in company name select
const renderCompanyOption = (props, option) => {
    const { id, name, customerNumber, department, place } = option;

    const firstRow = customerNumber && customerNumber !== name ? `${name}, ${customerNumber}` : name;

    const secondRow = department ? `${place}, ${department}` : place;
    const rowsData = [firstRow, secondRow].filter(Boolean);

    return (
        <Box {...props} key={id}>
            {rowsData.map((item) => (
                <div key={item}>{item}</div>
            ))}
        </Box>
    );
};

const Company = ({ booking, onChange, bookingError, touchBooking }) => {
    const { t } = useTranslation();

    // data for company name select
    const [companyNames, setCompanyNames] = useState([]);

    // data entered in company name field
    const [inputCompanyName, setInputCompanyName] = useState(booking.company.name);

    const onInputCompanyName = (value, reason) => {
        setInputCompanyName(value);

        if (reason === 'input' && value.trim()) {
            CompanyService.search(value)
                .then((data) => {
                    if (data && data.length) {
                        // set data for company name select
                        setCompanyNames(data);

                        if (booking.company.name && !data.find((item) => item.name === value)) {
                            onChange(RESET_COMPANY_DATA);
                        }
                    } else {
                        onChange(RESET_COMPANY_DATA);

                        // reset data for company name select
                        setCompanyNames([]);
                    }
                })
                .catch((error) => {
                    console.error('Company names have not been loaded', error);
                });
        }
    };

    const onCompanyNameSelect = (data) => {
        if (!data) {
            onChange(RESET_COMPANY_DATA);
            setCompanyNames([]);
            return;
        }

        CompanyService.getByName(data.name)
            .then((results) => {
                const chosenCompany = results.find((item) => item.id === data.id);

                const companyData = {
                    ...chosenCompany,
                    companiesByDepartment: results, // is used for departments select
                };

                onChange({
                    target: { name: 'company', value: companyData },
                });

                setCompanyNames([]);
            })
            .catch((error) => {
                console.error('Companies have not been loaded', error);
            });
    };

    const onDepartmentSelect = (data) => {
        const company = booking.company.companiesByDepartment.find((item) => item.department === data.target.value);

        // when department is selected we update company data as it depends on department
        const companyData = {
            ...company,
            companiesByDepartment: booking.company.companiesByDepartment,
        };

        onChange({ target: { name: 'company', value: companyData } });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '10px 0 0',
            }}
        >
            <CompanyField>
                <Autocomplete
                    freeSolo
                    value={{ name: inputCompanyName }}
                    onChange={(_, value) => onCompanyNameSelect(value)}
                    inputValue={inputCompanyName}
                    onInputChange={(_, value, reason) => onInputCompanyName(value, reason)}
                    options={companyNames}
                    renderOption={(props, option) => renderCompanyOption(props, option)}
                    getOptionLabel={(option) => option.name || ''}
                    fullWidth
                    renderInput={(params) => (
                        <AlTextField
                            {...params}
                            name="company.name"
                            placeholder={t('placeholder.companyName')}
                            onBlur={touchBooking}
                            error={bookingError('company.name')}
                            required={true}
                        />
                    )}
                />
            </CompanyField>
            {booking.company.id && (
                <>
                    <CompanyAddress data={booking.company} />
                    {booking.company.department && (
                        <CompanyField>
                            <Select
                                fullWidth
                                name="company.department"
                                value={booking.company.department}
                                onChange={onDepartmentSelect}
                            >
                                {booking.company.companiesByDepartment.map((item) => (
                                    <MenuItem value={item.department} key={item.department}>
                                        {item.department}
                                    </MenuItem>
                                ))}
                            </Select>
                        </CompanyField>
                    )}
                    {booking.company.orderNumberRequired && (
                        <CompanyField>
                            <AlTextField
                                fullWidth
                                name="company.orderNumber"
                                onChange={onChange}
                                value={booking.company.orderNumber || ''}
                                placeholder={t('placeholder.orderNumber')}
                                onBlur={touchBooking}
                                error={bookingError('company.orderNumber')}
                                required={true}
                            />
                        </CompanyField>
                    )}
                    {booking.company.customerCostCenterRequired && (
                        <CompanyField>
                            <AlTextField
                                fullWidth
                                name="company.costCenter"
                                onChange={onChange}
                                value={booking.company.costCenter || ''}
                                placeholder={t('placeholder.costCenter')}
                                onBlur={touchBooking}
                                error={bookingError('company.costCenter')}
                                required={true}
                            />
                        </CompanyField>
                    )}
                </>
            )}
        </Box>
    );
};

export { Company };
