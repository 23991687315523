import React, { useEffect } from 'react';
import css from './BookingDetailsForm.module.css';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AlTextField } from '../../../common/components/AlTextField';
import { PhoneField } from '../../../common/components/PhoneField';

const UserContacts = ({ booking, onChange, bookingError, touchBooking, disabled }) => {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = React.useState(null);

    useEffect(() => {
        if (bookingError('user.mobile') && !errorMessage) {
            setErrorMessage('booking.errorPhone');
        } else {
            setErrorMessage(null);
        }
    }, [bookingError('user.mobile')]);

    return (
        <>
            <div className={css.row}>
                <div className={css.item}>
                    <PhoneField
                        name="user.mobile"
                        defaultCountry="DE"
                        placeholder={t('mobile')}
                        value={booking.user.mobile || ''}
                        onChange={onChange}
                        onBlur={touchBooking}
                        error={bookingError('user.mobile')}
                        disabled={disabled}
                        required
                    />
                    {errorMessage && (
                        <Typography variant="caption" component="div" color="error" sx={{ mt: 0.5, ml: 0, mb: 0.5 }}>
                            {t(errorMessage)}
                        </Typography>
                    )}
                </div>
                <div className={css.item}>
                    <PhoneField
                        name="user.address.phone"
                        placeholder={t('phone')}
                        value={booking.user.address.phone || ''}
                        onChange={onChange}
                        onBlur={touchBooking}
                        error={bookingError('user.address.phone')}
                        disabled={disabled}
                    />
                </div>
            </div>
            <div className={css.row}>
                <div className={css.item}>
                    <AlTextField
                        fullWidth
                        name="user.email"
                        onChange={onChange}
                        value={booking.user.email || ''}
                        placeholder={t('email')}
                        alTooltip={t('tooltip.user.email')}
                        onBlur={touchBooking}
                        error={bookingError('user.email')}
                        required={true}
                        disabled={disabled}
                    />
                </div>
                <div className={css.item}>
                    <AlTextField
                        fullWidth
                        name="user.ccemail"
                        onChange={onChange}
                        value={booking.user.ccemail || ''}
                        placeholder={t('cc-email')}
                        alTooltip={t('tooltip.user.ccemail')}
                        onBlur={touchBooking}
                        error={bookingError('user.ccemail')}
                        disabled={disabled}
                    />
                </div>
            </div>
            <Typography
                variant="caption"
                sx={{
                    display: 'block',
                    mb: 3,
                    lineHeight: 1.4,
                }}
            >
                {t('email-message')}
            </Typography>
        </>
    );
};

export { UserContacts };
