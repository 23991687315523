import { setIn } from '../../common/utils/setIn';
import { getIn } from '../../common/utils/getIn';

const KEY = 'air_user';

const getCurrentUser = () => {
    if (!localStorage) {
        return null;
    }

    const userStr = localStorage.getItem(KEY);

    if (!userStr) {
        return null;
    }

    try {
        return JSON.parse(userStr) || null;
    } catch (e) {
        console.error('Error on parsing current user', e);
        return null;
    }
};

const setCurrentUser = (user) => {
    if (!localStorage) {
        return;
    }

    if (user) {
        const userToStore = [
            'firstName',
            'lastName',
            'companyName',
            'customerTypeId',
            'salutationId',
            'titleId',
            'agencyId',
            'mobile',
            'email',
            'ccemail',
            'address.zip',
            'address.place',
            'address.street',
            'address.countryId',
            'address.phone',
            'id',
            'allowedPaymentTypes',
            'token',
        ].reduce((r, key) => {
            setIn(r, key, getIn(user, key));
            return r;
        }, {});

        localStorage.setItem(KEY, JSON.stringify(userToStore));
    } else {
        localStorage.removeItem(KEY);
    }
};

const CurrentUser = {
    get: getCurrentUser,
    set: setCurrentUser,
};

export { CurrentUser };
