import { hasValidationError } from '../../../common/utils/hasValidationError';

const categoryListValidationErrors = ({ firstTransfer, secondTransfer }) => {
    const validationErrors = {};
    if (!firstTransfer.serviceTypeId || (secondTransfer && !secondTransfer.serviceTypeId)) {
        validationErrors.serviceTypeId = 'required';
    }

    const isValid = !hasValidationError(validationErrors);

    return isValid ? null : validationErrors;
};

export { categoryListValidationErrors };
