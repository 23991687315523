import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Alert, Button } from '@mui/material';
import { DateTime } from 'luxon';
import { OFFICE_PHONE, LATE_MINUTE_HOUR, GERMAN_TIMEZONE } from '../../../constants';
import { TRANSFER } from '../../models/transfer';

const checkOrderLate = () => {
    return DateTime.now().setZone(GERMAN_TIMEZONE).c.hour >= LATE_MINUTE_HOUR.FROM;
};

const checkBookingEarlyNextDay = (dateTime) => {
    const lastMinuteTo = DateTime.now().plus({ day: 1 }).set({ hours: LATE_MINUTE_HOUR.TO }).startOf('hour');

    return dateTime < lastMinuteTo;
};

const checkLastMinuteTransfer = (transfer) => {
    let bookingDateTime;

    switch (transfer.typeId) {
        case TRANSFER.TO_MUNICH:
        case TRANSFER.TO_NUREMBERG:
            bookingDateTime = DateTime.fromISO(transfer.flight.date + 'T' + transfer.arrivalTime);
            break;

        case TRANSFER.FROM_MUNICH:
        case TRANSFER.FROM_NUREMBERG:
            bookingDateTime = DateTime.fromISO(transfer.flight.date + 'T' + transfer.flight.time);
            break;

        case TRANSFER.SPECIAL:
            bookingDateTime = DateTime.fromISO(transfer.tripDate + 'T' + transfer.tripTime);
            break;

        default:
            return false;
    }

    const isBookingEarlyNextDay = checkBookingEarlyNextDay(bookingDateTime);
    const isOrderLate = checkOrderLate();

    return isOrderLate && isBookingEarlyNextDay;
};

const isLastMinuteBooking = (transfer1, transfer2) => {
    return [transfer1, transfer2].filter(Boolean).some(checkLastMinuteTransfer);
};

const OrderActions = ({ firstTransfer, secondTransfer, isBookingsValid, children }) => {
    const { t } = useTranslation();

    if (isBookingsValid && isLastMinuteBooking(firstTransfer, secondTransfer)) {
        return (
            <Alert
                variant="filled"
                icon={false}
                sx={{
                    marginLeft: '42px',
                }}
                severity="warning"
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="caption" sx={{ mr: 1, lineHeight: 1.4, color: 'text.primary' }}>
                        {t('shortTermBooking')}
                    </Typography>
                    <Button
                        type="button"
                        variant="outlined"
                        sx={{
                            flexShrink: 0,
                            color: 'text.primary',
                            borderColor: 'text.primary',
                        }}
                        href={`tel:${OFFICE_PHONE}`}
                    >
                        <Box sx={{ whiteSpace: 'nowrap' }}>{OFFICE_PHONE}</Box>
                    </Button>
                </Box>
            </Alert>
        );
    }

    return children;
};

export { OrderActions };
