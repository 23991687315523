const getErrorMessageFromResponseError = (e, t) => {
    if (typeof e === 'object' && e.name) {
        return {
            message: t(`error.${e.name}`),
            details: (e.details && e.details.map((detail) => t(`error.${e.name}Details.${detail}`))) || [],
        };
    }
    return { message: (e && e.message) || (typeof e === 'string' && e) || t('error.unknown') };
};

export { getErrorMessageFromResponseError };
