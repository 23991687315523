import { TRANSFER } from '../../models/transfer';
import { CUSTOMER_COMPANY_ID, CUSTOMER_PRIVATE_ID } from '../../../constants';

/**
 * It removes from booking object data which is for internal use and corrects some properties
 * For now it cleans:
 * - addressFrom
 * - addressTo
 * - serviceType
 * - flight
 * - price
 * - busy
 * - touched
 * Corrects:
 * - adults
 * - children
 * - kids
 * - extras
 * - user customerTypeId
 */
const bookingClean = (booking) => {
    let r = booking;

    for (let i = 0; i <= 1; i++) {
        if (!r.transfers[i]) {
            continue;
        }
        if (r.transfers[i].typeId === TRANSFER.SPECIAL) {
            r = r.setIn(['transfers', i], r.transfers[i].without('flight'));
            r = r.setIn(
                ['transfers', i, 'addressFrom'],
                r.transfers[i].addressFrom.without('id', 'type', 'placeValue'),
            );
            r = r.setIn(['transfers', i, 'addressTo'], r.transfers[i].addressTo.without('id', 'type', 'placeValue'));
            r = r.setIn(['transfers', i, 'extras'], {});
            if (!r.getIn(['transfers', i, 'addressFrom', 'zip'])) {
                r = r.setIn(['transfers', i, 'addressFrom', 'zip'], '-');
            }
            if (!r.getIn(['transfers', i, 'addressTo', 'zip'])) {
                r = r.setIn(['transfers', i, 'addressTo', 'zip'], '-');
            }
        }
        if ([TRANSFER.FROM_MUNICH, TRANSFER.FROM_NUREMBERG].includes(r.transfers[i].typeId)) {
            r = r.setIn(['transfers', i], r.transfers[i].without('addressFrom'));
            r = r.setIn(['transfers', i, 'addressTo'], r.transfers[i].addressTo.without('id', 'type', 'placeValue'));
            if (r.getIn(['transfers', i, 'extras', 'ARRIVE_EARLY'])) {
                r = r.setIn(['transfers', i, 'extras', 'ARRIVE_EARLY'], undefined);
            }
            if (r.getIn(['transfers', i, 'extras', 'ARRIVE_LATE'])) {
                r = r.setIn(['transfers', i, 'extras', 'ARRIVE_LATE'], undefined);
            }
        }
        if ([TRANSFER.TO_MUNICH, TRANSFER.TO_NUREMBERG].includes(r.transfers[i].typeId)) {
            r = r.setIn(['transfers', i], r.transfers[i].without('addressTo'));
            r = r.setIn(
                ['transfers', i, 'addressFrom'],
                r.transfers[i].addressFrom.without('id', 'type', 'placeValue'),
            );
            if (r.getIn(['transfers', i, 'extras', 'GATE'])) {
                r = r.setIn(['transfers', i, 'extras', 'GATE'], undefined);
            }
        }

        r = r.setIn(['transfers', i], r.transfers[i].without('serviceType'));

        r = ['adults', 'children', 'kids'].reduce((ri, key) => {
            if (ri.transfers[i][key]) {
                return ri;
            }
            return ri.setIn(['transfers', i, key], 0);
        }, r);

        // count suitcases including free of charge
        if (r.transfers[i].extras.onlyHandLuggage) {
            /*
                is this really needed? seems the object comes without anything else than the
                onlyHandLuggage property anyway (might be it's returned like that from the
                server). anyway, it's not working the way it was written before.
             */
            r = r.setIn(['transfers', i, 'extras'], r.transfers[i].extras.without('ADD'));
        } else {
            const totalADD = (r.transfers[i].extras.ADD || 0) + r.transfers[i].adults + r.transfers[i].children;

            r = r.setIn(['transfers', i, 'extras', 'ADD'], totalADD);
        }

        // convert extras to string
        r = r.setIn(['transfers', i, 'extras'], JSON.stringify(r.transfers[i].extras));
    }

    r = r.setIn(
        ['user', 'customerTypeId'],
        r.getIn(['user', 'companyName']) ? CUSTOMER_COMPANY_ID : CUSTOMER_PRIVATE_ID,
    );

    if (r.company.id) {
        r = r.setIn(['companyId'], r.getIn(['company', 'id']));

        if (r.company.costCenter) {
            r = r.setIn(['customerCostCenter'], r.getIn(['company', 'costCenter']));
        }

        if (r.company.orderNumber) {
            r = r.setIn(['orderNumber'], r.getIn(['company', 'orderNumber']));
        }
    }

    r = r.without('price', 'busy', 'touched', 'company');

    return r;
};

export { bookingClean };
