import React, { useEffect, useRef, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import css from './BookingDetailsForm.module.css';
import { AlTextField } from '../../../common/components/AlTextField';
import { ExpansionPanelSummary } from '../../../common/components/ExpansionPanelSummary';
import { PhoneField } from '../../../common/components/PhoneField';

const DifferentPassenger = ({ booking, onChange, bookingError, touchBooking }) => {
    const { t } = useTranslation();
    const firstRef = useRef(null);
    const [isExpanded, setExpanded] = useState(false);

    useEffect(() => {
        if (!isExpanded) {
            return;
        }
        firstRef.current.focus();
    }, [isExpanded]);

    return (
        <Accordion onChange={(_, expanded) => setExpanded(expanded)}>
            <ExpansionPanelSummary text="alternativePassenger.title" />
            <div className={css.expansionBody}>
                <div className={css.row}>
                    <div className={css.item}>
                        <AlTextField
                            autoComplete="off"
                            fullWidth
                            name="passengerFirstName"
                            onChange={onChange}
                            value={booking.passengerFirstName || ''}
                            placeholder={t('first-name')}
                            alTooltip={t('tooltip.passengerFirstName')}
                            InputProps={{ inputRef: firstRef }}
                        />
                    </div>
                    <div className={css.item}>
                        <AlTextField
                            autoComplete="off"
                            fullWidth
                            name="passengerLastName"
                            onChange={onChange}
                            value={booking.passengerLastName || ''}
                            placeholder={t('last-name')}
                            alTooltip={t('tooltip.passengerLastName')}
                        />
                    </div>
                </div>
                <Box
                    sx={{
                        boxSizing: 'border-box',
                        maxWidth: {
                            xs: '100%',
                            sm: '50%',
                        },
                        paddingRight: { sm: '5px' },
                    }}
                >
                    <PhoneField
                        name="passengerPhone"
                        placeholder={t('alternativePassenger.phone')}
                        value={booking.passengerPhone || ''}
                        onChange={onChange}
                        onBlur={touchBooking}
                        error={bookingError('passengerPhone')}
                    />
                </Box>
            </div>
        </Accordion>
    );
};

export { DifferentPassenger };
