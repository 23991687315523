import React from 'react';
import { useTranslation } from 'react-i18next';
import { faCalendar, faClock, faPlane, faPlaneDeparture } from '@fortawesome/free-solid-svg-icons';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import moment from 'moment';
import { Box, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Time } from '../../../common/components/Time';
import { AlTextField } from '../../../common/components/AlTextField';
import { InputRow } from './InputRow';

const DetailToAirportFields = ({ transfer, onDateChange, onTimeChange, onChange, touchTransfer, transferError }) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);

    const dateValue = (dateString) => {
        return (dateString && moment(dateString, 'YYYY-MM-DD')) || dateString;
    };

    // to start the week on Monday
    moment.updateLocale('en', {
        week: {
            dow: 1,
        },
    });

    const minDate = moment().add(1, 'd');

    return (
        <>
            <InputRow icon={faCalendar}>
                <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    <MobileDatePicker
                        open={open}
                        onOpen={() => {
                            touchTransfer({ name: 'flight.date' });
                            setOpen(true);
                        }}
                        onClose={() => setOpen(false)}
                        sx={{ width: '100%' }}
                        name="flight.date"
                        format="DD.MM.YYYY"
                        value={dateValue(transfer.flight.date)}
                        minDate={minDate}
                        onChange={(date) => {
                            onDateChange({ date, name: 'flight.date' });
                            setOpen(false);
                        }}
                        required
                        slotProps={{
                            textField: {
                                error: transferError('flight.date'),
                                placeholder: t('flight.departureDate'),
                            },
                        }}
                    />
                    <Box sx={{ position: 'absolute', right: '22px', top: '2px' }}>*</Box>
                    <Tooltip
                        title={t('tooltip.flight.departureDate')}
                        placement="right"
                        enterTouchDelay={0}
                        leaveTouchDelay={3000}
                    >
                        <HelpOutlineIcon
                            sx={{
                                position: 'absolute',
                                p: 0.5,
                                right: 0,
                                top: 0,
                                fontSize: '16px',
                                color: 'text.secondary',
                            }}
                        />
                    </Tooltip>
                </Box>
            </InputRow>
            <InputRow icon={faPlane}>
                <AlTextField
                    fullWidth
                    name="flight.flightNumber"
                    onBlur={touchTransfer}
                    onChange={onChange}
                    value={transfer.flight.flightNumber || ''}
                    placeholder={t('flight-number')}
                    alTooltip={t('tooltip.flight.number')}
                    required={true}
                    error={transferError('flight.flightNumber')}
                />
            </InputRow>
            <InputRow icon={faPlaneDeparture}>
                <AlTextField
                    fullWidth
                    name="flight.flightDestination"
                    onBlur={touchTransfer}
                    onChange={onChange}
                    value={transfer.flight.flightDestination || ''}
                    placeholder={t('placeholder.flightDestination')}
                    alTooltip={t('tooltip.flight.destination')}
                    required={true}
                    error={transferError('flight.flightDestination')}
                />
            </InputRow>
            <InputRow icon={faClock}>
                <Time
                    error={transferError('flight.time')}
                    value={transfer.flight.time}
                    onBlur={() => touchTransfer({ name: 'flight.time' })}
                    onChange={(time) => onTimeChange({ time, name: 'flight.time' })}
                    minutesTooltip={t('tooltip.flight.departureTime')}
                    required={true}
                    label={t('label.departureTime')}
                />
            </InputRow>
            <InputRow icon={faClock}>
                <Time
                    error={transferError('arrivalTime')}
                    value={transfer.arrivalTime}
                    onBlur={() => touchTransfer({ name: 'arrivalTime' })}
                    onChange={(time) => onTimeChange({ time, name: 'arrivalTime' })}
                    minutesTooltip={t('tooltip.flight.arriveAtAirportTime')}
                    required={true}
                    label={t('label.arriveAtAirportTime')}
                />
            </InputRow>
        </>
    );
};

export { DetailToAirportFields };
